import React, {useRef, useState} from 'react';
import axios from "axios";
import {useOnClickOutside} from "usehooks-ts";
import {useSelector} from "react-redux";

const CancelToken = axios.CancelToken;
let source: any;

function SingleSearch({onChange, placeholder, onSearch, selectCallback, secured, tips}: any) {

  const token = useSelector(state => state.user.token)

  const [elements, setElements] = useState<any[]>([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  const ref = useRef(null)

  const handleClickOutside = () => {

    if (elements.length > 0) {

      setElements([])
      setInput('')

    }
  }

  useOnClickOutside(ref, handleClickOutside)

  const select = (element: any) => {

    onChange(element)
    setElements([])
    if (selectCallback) {
      console.log(element, selectCallback)
      setInput(selectCallback(element))
    } else {
      setInput(element)
    }

  }

  const search = async (text: any) => {

    if (source) {
      source.cancel();
    }

    setInput(text);

    if (text.length > 1) {

      source = CancelToken.source();
      setLoading(true);

      let elements = secured ? await onSearch(token, source.token, text) : await onSearch(source.token, text);
      setElements(elements);
      setLoading(false);

    } else {
      setElements([]);
    }

  }

  return (
    <>
      <div className="custom-select-select" ref={ref}>
        <div className="custom-select-top row">
          <input
            type="text"
            className={"custom-select-top-input"}
            placeholder={placeholder}
            value={input}
            onChange={(event) => {
              search(event.target.value);
            }}
          />
          {loading && (
            <div className="custom-select-top-spinner">
              <div className="spinner"/>
            </div>
          )}
        </div>
        {
          (elements.length || input.length > 0) > 0 && (
            <div className="custom-select-submenu">
              {
                elements.map(function (element: any, index) {
                  return (
                    <span
                      key={index}
                      className="custom-select-submenu-element"
                      onClick={() => {
                        select(element);
                      }}
                    >
                    {selectCallback ? selectCallback(element) : element}
                  </span>
                  )
                })
              }
            </div>
          )
        }
      </div>
      {tips && tips.length > 0 && (
        <div className="custom-text-field-input-tips">
          {tips.map((tip: {value: any, label: string}) => {
            return (
              <span className="custom-text-field-input-tip" onClick={() => {select(tip.value)}}>{tip.label}</span>
            )
          })}
        </div>
      )}
    </>
  );
}

export default SingleSearch
