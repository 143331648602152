import React, {useRef, useState} from 'react';
import {IMAGE_URL} from "../../../core/common";
import {useOnClickOutside} from "usehooks-ts";

function MessengerSelect({contacts, contact, onChange} : any) {

  const [open, setOpen] = useState<boolean>(false)

  const ref = useRef(null)

  const handleClickOutside = () => {
   setOpen(false)
  }

  useOnClickOutside(ref, handleClickOutside)

  const select = (contact: any) => {

    setOpen(false)
    onChange(contact)

  }

  return (
    <div className="custom-select" ref={ref}>
      <div className="custom-select-top row" onClick={() => {
        setOpen(true)
      }}>
        {contact !== null && (
          <>
            <img src={IMAGE_URL + contact.icon} className="custom-select-icon"/>
            <span className="custom-select-top-label">{contact.name}</span>
          </>
        )}
        <img src="/images/arrow.svg" alt="" className="custom-select-top-arrow"/>
      </div>
      {open &&
        <div className="custom-select-submenu">
          {contacts.map((contact: any) => {
            return (
              <span className="custom-select-submenu-element" onClick={() => {
                select(contact)
              }}>
                <img src={IMAGE_URL + contact.icon} className="custom-select-icon"/>
                {contact.name}
             </span>
            )
          })}
        </div>
      }
    </div>
  );
}

export default MessengerSelect;
