import React, {useState} from 'react';
import Modal from "../../../ui/Modal";
import {formCheckErrors, formGenerateErrorsObject} from "../../../../core/form";
import {renderField} from "../../../../fields";
import Button from "../../../ui/Button";
import {useSelector} from "react-redux";
import {userFields} from "../../../../fields/user";
import {apiUserChangePassword} from "../../../../actions/userActions";
import useModal from "../../../../hooks/useModal";
import ConfirmModal from "../../../modals/ConfirmModal";
import {userSignOutAction} from "../../../../reducers/user";

const errorsList: any = {
  password: 'password',
  repeatPassword: 'repeat'
}

const initialValues: any = {
  password: '',
  repeatPassword: ''
}

function StringerModalChangePassword({open, onClose} : any) {

  const token = useSelector(state => state.user.token)

  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [loading, setLoading] = useState(false)

  const [successModalOpen, openSuccessModal, closeSuccessModal] = useModal();

  const getFieldActions = (key: string) => {
    return {
      value: values[key],
      error: errors[key],
      onChange: (value: any) => {
        setValues({
          ...values,
          [key]: value,
        })
        setErrors({
          ...errors,
          [key]: '',
        })
      }
    }
  }

  const submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setLoading(true)
    await apiUserChangePassword(token, values['password'])
    setValues(initialValues)
    onClose()
    openSuccessModal()

  }

  return (
    <>
      <ConfirmModal
        open={successModalOpen}
        onClose={closeSuccessModal}
        title='Success'
        message={'Your password has been successfully changed'}
        submitButtonText='OK'
        onUpdate={() => {
          window.location.reload()
        }}
      />
      <Modal
        open={open}
        onClose={onClose}
        content={(
          <div className="modal-window">
            <div className="modal-window-top">
              <span className="modal-window-top-title">Change password</span>
              <div className="modal-window-top-close" onClick={() => onClose()}/>
            </div>
            <div className="modal-window-content">
              <div className="modal-window-content-inner">
                <div className="modal-window-row">
                  {renderField({
                    ...userFields.password,
                    ...getFieldActions('password'),
                    required: true,
                  })}
                </div>
                <div className="modal-window-row">
                  {renderField({
                    ...userFields.repeatPassword,
                    ...getFieldActions('repeatPassword'),
                    required: true,
                  })}
                </div>
              </div>
            </div>
            <div className="modal-window-bottom">
              <div className="modal-window-bottom-buttons">
                <button className="modal-window-bottom-cancel-button" onClick={onClose}>Cancel</button>
                <Button
                  className={'modal-window-bottom-submit-button'}
                  label={'Submit'}
                  loading={loading}
                  onClick={submit}
                  type={'button'}
                />
              </div>
            </div>
          </div>
        )}
      />
    </>
  )

}

export default StringerModalChangePassword
