import Cookies from "js-cookie";

const USER_SIGN_IN = 'USER_SIGN_IN';
const USER_SIGN_OUT = 'USER_SIGN_OUT';

const defaultState = {
  isLoggedIn: false,
  user: null,
  token: null,
};

type ActionProps = {
  type: string,
  payload: any
}


export const userReducer = (state = defaultState, action: ActionProps) => {
  switch (action.type) {
    case USER_SIGN_IN:
      Cookies.set('token', action.payload.token, { path: '/' })
      return {...state, isLoggedIn: true, user: action.payload.user, token: action.payload.token};
    case USER_SIGN_OUT:
      Cookies.remove('token', { path: '/' })
      return {...state, isLoggedIn: false, user: null, token: null};
    default:
      return state;
  }
};

export const userSignInAction = (payload : object) => ({type: USER_SIGN_IN, payload});
export const userSignOutAction = () => ({type: USER_SIGN_OUT});
