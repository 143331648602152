import React, {useState} from 'react';

type ActivationProps = {
  email: string,
  onSignOut: Function
};

function Activation({email, onSignOut} : ActivationProps) {

  const [loading, setLoading] = useState(false)

  const SignOut = () => {
    onSignOut();
  }

  const recentEmail = () => {
    setLoading(true);
  }

  return (
    <div className="activation-window">
      <div className="activation-window-top">
        <div className="activation-window-top-icon"/>
      </div>
      <div className="activation-window-content">
        <span className="activation-window-title">Almost ready</span>
        <span className="activation-window-text">Mail has been sent to your e-mail <b> {email} </b> with a link to activate your account.</span>
        <span className="activation-window-text">If you have not received the letter, you can</span>
        <button className={`custom-button activation-window-button ${loading ? "loading" : ""}`} onClick={recentEmail}>
          <span className="custom-button-label">Resend email</span>
          {loading && <span className="custom-button-spinner"><span className="spinner"/></span>}
        </button>
        <span className="activation-window-logout" onClick={SignOut}>You can also sign out from your account</span>
      </div>
    </div>
  );
}

export default Activation;
