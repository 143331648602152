import React, {useEffect, useRef, useState} from 'react';
import {useOnClickOutside} from "usehooks-ts";

type FilterMenuProps = {
  filter: any
}

function FilterMenu({filter}: FilterMenuProps) {

  const [open, setOpen] = useState(false)

  const ref = useRef(null)

  const handleClickOutside = () => {
    setOpen(false)
  }

  useOnClickOutside(ref, handleClickOutside)

  useEffect(() => {

    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

  }, [open])

  const closeMenu = () => {
    setOpen(false)
  }

  if (open) {
    return (
      <div className="filter-menu" ref={ref}>
        <div className="filter-menu-close" onClick={() => {
          setOpen(false)
        }}>
          <img src="/images/menu-bar.svg" alt="" className="filter-menu-close-icon"/>
        </div>
        <div className="filter-menu-content">
          {filter}
        </div>
      </div>
    );
  }

  return (
    <div className="video-page-filter-icon" onClick={() => {
      setOpen(true)
    }}>
      <i className="fa fa-filter"/>
    </div>
  )

}

export default FilterMenu
