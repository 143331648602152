import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {formCheckErrors, formGenerateErrorsObject} from "../../../../core/form";
import Modal from "../../../../components/ui/Modal";
import Field from "../../../../components/ui/Field";
import Button from "../../../../components/ui/Button";
import {apiStringerRegister} from "../../../../actions/stringerActions";
import {apiLocationCountriesGet} from "../../../../actions/locationActions";
import {apiClientRegister} from "../../../../actions/clientActions";


const errorsList: any = {
  firstName: 'required',
  lastName: 'required',
  phone: 'required',
  country: 'required',
  position: 'required',
  organization: 'required',
}

const initialValues: any = {
  firstName: '',
  lastName: '',
  phone: '',
  country: null,
  position: '',
  organization: '',
}

function CompleteMediaModal({open, onClose} : any) {

  const token = useSelector(state => state.user.token)

  const [values, setValues] = useState<any>(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [submitLoading, setSubmitLoading] = useState(false)

  const changeField = (field: string, value: any) => {
    setValues({...values, [field]: value})
    setErrors({...errors, [field]: ""})
  }

  const submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setSubmitLoading(true);

    try {

      const apiResponse = await apiClientRegister(token, values);
      if (apiResponse.status) {
       window.location.reload()
      } else {
        setSubmitLoading(false);
      }

    } catch (error) {
      console.log(error);
    }

  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={(
        <div className="modal-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Complete registration as a media</span>
            <div className="modal-window-top-close" onClick={() => onClose()}/>
          </div>
          <div className="modal-window-content">
            <div className="modal-window-content-inner">
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  <Field
                    id="profileFirstName"
                    label="First Name *"
                    type="input"
                    error={errors.firstName}
                    value={values.firstName}
                    onChange={(value) => changeField("firstName", value)}
                  />
                </div>
                <div className="modal-window-col">
                  <Field
                    id="profileLastName"
                    label="Last Name *"
                    type="input"
                    error={errors.lastName}
                    value={values.lastName}
                    onChange={(value) => changeField("lastName", value)}
                  />
                </div>
              </div>
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  <Field
                    id="profileOrganization"
                    label="Organization *"
                    type="input"
                    error={errors.organization}
                    value={values.organization}
                    onChange={(value) => changeField("organization", value)}
                  />
                </div>
                <div className="modal-window-col">
                  <Field
                    id="profilePosition"
                    label="Position *"
                    type="input"
                    error={errors.position}
                    value={values.position}
                    onChange={(value) => changeField("position", value)}
                  />
                </div>
              </div>
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  <Field
                    id="profileCountry"
                    label="Country *"
                    type="singleSelect"
                    options={{
                      onLoadElements: apiLocationCountriesGet,
                      placeholder: 'Select country'
                    }}
                    error={errors.country}
                    value={values.country}
                    onChange={(value) => changeField("country", value)}
                  />
                </div>
                <div className="modal-window-col">
                  <Field
                    id="profilePhone"
                    label="Phone *"
                    type="input"
                    error={errors.phone}
                    value={values.phone}
                    onChange={(value) => changeField("phone", value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <button className="modal-window-bottom-cancel-button" onClick={onClose}>Cancel</button>
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Submit'}
                loading={submitLoading}
                onClick={submit}
                type={'button'}
              />
            </div>
          </div>
        </div>
      )}
    />
  )

}

export default CompleteMediaModal
