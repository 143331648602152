import React, {useEffect, useState} from 'react';
import {Link, useOutletContext, useParams} from "react-router-dom";
import {isEmpty} from "../../../core/helpers";
import Loading from "../../../components/ui/Loading";
import moment from "moment";
import {CLOUDFRONT_URL} from "../../../core/common";
import {apiTaskFindPublic} from "../../../actions/taskActions";
import {useSelector} from "react-redux";
import Button from "../../../components/ui/Button";
import {apiStringerResponse} from "../../../actions/stringerActions";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import useModal from "../../../hooks/useModal";
import PageNotFound from "../../pagenotfound/PageNotFound";

function TaskPage() {

  const content: any = useOutletContext();

  const userId = useSelector(state => state.user.user?.id)
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const role = useSelector(state => state.user?.user?.role);
  const token = useSelector(state => state.user.token)

  const { id } = useParams();

  const [successModalOpen, openSuccessModal, closeSuccessModal] = useModal();

  const [loading, setLoading] = useState(true)
  const [task, setTask] = useState<any>(null)
  const [interestedLoading, setInterestedLoading] = useState(false)
  const [interested, setInterested] = useState(false)

  useEffect(() => {
    loadData();
  }, [id])

  const loadData = async () => {

    if (id !== undefined) {

      const response : any = await apiTaskFindPublic(token, id.toString());
      if (response.status) {
        setTask(response.task)
      }
      setLoading(false)

    }

  }

  if (loading && task == null) {
    return <Loading/>
  }

  if (!loading && task == null) {
    return <PageNotFound/>
  }

  const handleInterested = async () => {

    if (isLoggedIn) {
      setInterestedLoading(true)
      await apiStringerResponse(token, task.id)
      setInterested(true)
      openSuccessModal()
    } else {
      content.onOpenSignInModal()
    }

  }

  return (
    <>
      <ConfirmModal
        open={successModalOpen}
        onClose={closeSuccessModal}
        title='Thank you!'
        message="Please wait for the moderator's reply."
        submitButtonText='OK'
      />
      <div className="video-page-top">
        <Link to={'/tasks'} className="video-page-top-back-icon">
          <i className="fa fa-angle-left"/>
        </Link>
        <span className="video-page-top-title">Task</span>
      </div>
      {task?.status === 1 && task?.user_id === userId && (
        <div className="create-page-warning-block">
          <img src="/images/warning-check.svg" alt="" className="create-page-warning-block-icon"/>
          <div className="create-page-warning-block-right">
            <span className="create-page-warning-block-title">Your task on moderation</span>
            <span className="create-page-warning-block-text">
            If you want to change or delete the task, you can write to
            <a href="mailto:info@stringershub.com" className="create-page-warning-block-text-link">info@stringershub.com</a>
          </span>
          </div>
        </div>
      )}
      <div className="brief">
        <div className="brief-left">
          <img src={`${CLOUDFRONT_URL}${task.static_image}`} alt="" className="brief-left-image"/>
        </div>
        <div className="brief-right">
          <h1 className="brief-title">{task.title}</h1>
          <div className="brief-elements">
            {!isEmpty(task.stringer_budget) && (
              <div className="brief-element">
                <span className="brief-element-label">Budget:</span>
                <span className="brief-element-value">{task.stringer_budget}$</span>
              </div>
            )}
            <div className="brief-element">
              <span className="brief-element-label">Deadline:</span>
              <span className="brief-element-value">{moment(task.deadline).format('DD/MM/YYYY')}</span>
            </div>
            {!isEmpty(task.location_text) && (
              <div className="brief-element">
                <span className="brief-element-label">Location:</span>
                <span className="brief-element-value">{task.location_text}</span>
              </div>
            )}
            {task.types.length > 0 && (
              <div className="brief-element">
                <span className="brief-element-label">Types of work:</span>
                <span className="brief-element-value">
                {task.types.map((type: any) => type.name).join(', ')}
              </span>
              </div>
            )}
          </div>
          {!isEmpty(task.description) && (
            <div className="brief-description" dangerouslySetInnerHTML={{__html: task.description.replace(/\n/g, "<br />")}}/>
          )}
          {role !== 'client' && !interested && (
            <Button
              className={'brief-upload-button'}
              label={'Interested'}
              loading={interestedLoading}
              onClick={handleInterested}
              type={'button'}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TaskPage
