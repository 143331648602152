import React, {useState} from 'react';
import {
  apiStringerUpdateComment,
  stringerCheckEquipments,
  stringerCheckGeneralInfo,
  stringerCheckSkills
} from "../../../actions/stringerActions";
import {isEmpty} from "../../../core/helpers";
import {IMAGE_URL} from "../../../core/common";
import Field from "../../ui/Field";
import Button from "../../ui/Button";
import {useSelector} from "react-redux";

function StringerGeneralInfo({stringer, editable, onOpenGeneralInfoModal, onOpenEquipmentsModal, onOpenSkillsModal} : any) {

  const user = useSelector(state => state.user.user)
  const token = useSelector(state => state.user.token)
  const [comment, setComment] = useState(stringer.comment)
  const [referrer, setReferrer] = useState(stringer.referrer)
  const [submitLoading, setSubmitLoading] = useState(false)

  const saveComment = async () => {
    setSubmitLoading(true)
    await apiStringerUpdateComment(token, stringer.id, comment, referrer)
    window.location.reload()
  }

  return (
    <>
      <div className="stringer-page-right-content-col">
        <div className="stringer-page-block">
          <div className="stringer-page-block-top">
            <span className="stringer-page-block-title">General information</span>
            {editable &&  <span className="stringer-page-block-edit-icon" onClick={() => onOpenGeneralInfoModal()}/>}
          </div>
          {(stringerCheckGeneralInfo(stringer) || !editable) ? (
            <>
              {!isEmpty(stringer.about_me) && (
                <div
                  className="stringer-page-block-description"
                  dangerouslySetInnerHTML={{__html: stringer.about_me.replace(/\n/g, "<br />")}}
                />
              )}
              <div className="stringer-page-block-element">
                <span className="stringer-page-block-element-label">Email:</span>
                <div className="stringer-page-block-element-value">
                  <span className="stringer-page-block-element-value-text">{stringer.user.email}</span>
                </div>
              </div>
              <div className="stringer-page-block-element">
                <span className="stringer-page-block-element-label">Location:</span>
                <div className="stringer-page-block-element-value">
                  <span className="stringer-page-block-element-value-text">{stringer.location_text}</span>
                </div>
              </div>
              <div className="stringer-page-block-element">
                <span className="stringer-page-block-element-label">Phone number:</span>
                <div className="stringer-page-block-element-value">
                  <span className="stringer-page-block-element-value-text">{stringer.phone}</span>
                </div>
              </div>
              {
                stringer.messengers.length > 0 &&
                <div className="stringer-page-block-element">
                  <span className="stringer-page-block-element-label">Messengers</span>
                  <div className="stringer-page-block-element-value">
                    {
                      stringer.messengers.map((messenger: any) => {
                        return (
                          <div className="stringer-page-block-element-messenger">
                            {messenger.contact && messenger.contact.icon !== null && <img src={IMAGE_URL + messenger.contact.icon} className="stringer-page-block-element-messenger-image"/>}
                            <span className="stringer-page-block-element-messenger-name">{messenger.contact && messenger.contact.name}:</span>
                            <span className="stringer-page-block-element-messenger-value">{messenger.value}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              }
            </>
          ): (
            <div className="stringer-page-block-empty">
              <div className="stringer-page-block-empty-icon stringer-page-block-empty-icon-skills"/>
              <span className="stringer-page-block-empty-text">Add some information about you</span>
              <span className="stringer-page-block-empty-link" onClick={() => onOpenGeneralInfoModal()}>Edit general info</span>
            </div>
          )}
        </div>
        <div className="stringer-page-block">
          <div className="stringer-page-block-top">
            <span className="stringer-page-block-title">Equipments information</span>
            {editable && <span className="stringer-page-block-edit-icon" onClick={() => onOpenEquipmentsModal()}/>}
          </div>
          {(stringerCheckEquipments(stringer) || !editable) ? (
            <>
              <div className="stringer-block-page-equipments">
                {stringer.cameras.length > 0 &&
                  <div className="stringer-page-block-equipments-element">
                    <img src="/images/camera.svg" alt="" className="stringer-page-block-equipments-element-icon stringer-page-block-equipments-element-icon-cameras"/>
                    <span className="stringer-page-block-equipments-element-text">
                      <b>Cameras:</b> {stringer.cameras.join(", ")}
                    </span>
                  </div>
                }
                {stringer.microphones.length > 0 &&
                  <div className="stringer-page-block-equipments-element">
                    <img src="/images/microphone.svg" alt="" className="stringer-page-block-equipments-element-icon stringer-page-block-equipments-element-icon-cameras"/>
                    <span className="stringer-page-block-equipments-element-text">
                      <b>Microphones:</b> {stringer.microphones.join(", ")}
                    </span>
                  </div>
                }
                {stringer.lens.length > 0 &&
                  <div className="stringer-page-block-equipments-element">
                    <img src="/images/lens.svg" alt="" className="stringer-page-block-equipments-element-icon stringer-page-block-equipments-element-icon-cameras"/>
                    <span className="stringer-page-block-equipments-element-text">
                      <b>Lens:</b> {stringer.lens.join(", ")}
                    </span>
                  </div>
                }
              </div>
              <div className="stringer-page-block-devices">
                {stringer.stabilizer &&
                  <div className="stringer-page-block-devices-element">
                    <img src="/images/check.svg" alt="" className="stringer-page-block-devices-element-icon"/>
                    <span className="stringer-page-block-devices-element-text">Stabilizer</span>
                  </div>
                }
                {stringer.drone &&
                  <div className="stringer-page-block-devices-element">
                    <img src="/images/check.svg" alt="" className="stringer-page-block-devices-element-icon"/>
                    <span className="stringer-page-block-devices-element-text">Drone</span>
                  </div>
                }
                {stringer.car_availability &&
                  <div className="stringer-page-block-devices-element">
                    <img src="/images/check.svg" alt="" className="stringer-page-block-devices-element-icon"/>
                    <span className="stringer-page-block-devices-element-text">Car availability</span>
                  </div>
                }
              </div>
            </>
          ) : (
            <div className="stringer-page-block-empty">
              <div className="stringer-page-block-empty-icon stringer-page-block-empty-icon-equipments"/>
              <span className="stringer-page-block-empty-text">Add equipments to show your professionalism</span>
              <span className="stringer-page-block-empty-link" onClick={() => onOpenEquipmentsModal()}>Add equipments</span>
            </div>
          )}
        </div>
        <div className="stringer-page-block">
          <div className="stringer-page-block-top">
            <span className="stringer-page-block-title">Skills & Pricing</span>
            {editable && <span className="stringer-page-block-edit-icon" onClick={() => onOpenSkillsModal()}/>}
          </div>
          {(stringerCheckSkills(stringer) || !editable) ? (
            <div>
              {stringer.rate_hour !== null &&
                <div className="stringer-page-block-element">
                  <span className="stringer-page-block-element-label">Rate per hour:</span>
                  <div className="stringer-page-block-element-value">
                    <span className="stringer-page-block-element-value-text">{stringer.rate_hour} $</span>
                  </div>
                </div>
              }
              {stringer.rate_day !== null &&
                <div className="stringer-page-block-element">
                  <span className="stringer-page-block-element-label">Rate per day:</span>
                  <div className="stringer-page-block-element-value">
                    <span className="stringer-page-block-element-value-text">{stringer.rate_day} $</span>
                  </div>
                </div>
              }
              {stringer.languages.length > 0 &&
                <div className="stringer-page-block-element">
                  <span className="stringer-page-block-element-label">Languages:</span>
                  <div className="stringer-page-block-element-value">
                    <span className="stringer-page-block-element-value-text">{stringer.languages.join(', ')}</span>
                  </div>
                </div>
              }
              {stringer.softwares.length > 0 &&
                <div className="stringer-page-block-element">
                  <span className="stringer-page-block-element-label">Software:</span>
                  <div className="stringer-page-block-element-value">
                    <span className="stringer-page-block-element-value-text">{stringer.softwares.join(', ')}</span>
                  </div>
                </div>
              }
            </div>
          ) : (
            <div className="stringer-page-block-empty">
              <div className="stringer-page-block-empty-icon stringer-page-block-empty-icon-skills"/>
              <span className="stringer-page-block-empty-text">Add skills to show your professionalism</span>
              <span className="stringer-page-block-empty-link" onClick={() => onOpenSkillsModal()}>Add skills</span>
            </div>
          )}
        </div>
      </div>
      <div className="stringer-page-right-content-col stringer-page-rating">
        <span className="stringer-page-rating-title">Work History</span>
        <div className="stringer-page-rating-empty">
          <div className="stringer-page-rating-empty-icon"/>
          {editable ? (
            <span className="stringer-page-rating-empty-text">Reviews about working with you will be displayed here</span>
          ) : (
            <>
              {user.company_id === 1 && (
                <div className="stringer-page-comment">
                  <Field
                    type={'textarea'}
                    label={'Comment'}
                    value={comment}
                    onChange={value => {
                      setComment(value)
                    }}
                    id={'stringerComment'}
                    options={{
                      minRows: 4
                    }}
                  />
                  <div style={{marginTop: '20px'}}>
                    <Field
                      id="stringerReferrer"
                      label="Referrer"
                      type="input"
                      value={referrer}
                      options={{
                        tips: ['Da', 'P', 'L', 'K', 'M', 'D', 'Yu', 'Y'].map(tip => {
                          return {
                            label: tip,
                            value: tip
                          }
                        })
                      }}
                      onChange={value => {
                        setReferrer(value)
                      }}
                    />
                  </div>
                  <div className="stringer-page-comment-bottom">
                    <Button
                      className={'blue stringer-page-comment-submit'}
                      label={'Save'}
                      disabled={submitLoading}
                      loading={submitLoading}
                      onClick={() => {
                        saveComment()
                      }}
                      type={'button'}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default StringerGeneralInfo;
