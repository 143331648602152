import React from 'react';

function Switcher({
  label,
  checked,
  onChange,
} : {
  label: string,
  checked: boolean,
  onChange: (checked: boolean) => void
}) {

  return (
    <div className={`custom-switcher ${checked && 'selected'}`} onClick={() => {
      onChange(!checked)
    }}>
      <div className="custom-switcher-inner">
        <span className="custom-switcher-inner-circle"/>
      </div>
      <span className="custom-switcher-label">{label}</span>
    </div>
  );
}

export default Switcher;
