import React from 'react';
import {useSelector} from "react-redux";
import ProfileComplete from "./complete/ProfileComplete";
import ProfileStringer from "./stringer/ProfileStringer";
import ProfileClient from "./client/ProfileClient";

function Profile() {

  const user = useSelector(state => state.user.user);

  if (user?.role === 'user') {
    return <ProfileComplete/>
  }

  if (user?.role === 'stringer') {
    return <ProfileStringer/>
  }

  if (user?.role === 'client') {
    return <ProfileClient/>
  }

  return <div/>

}

export default Profile;
