import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {formCheckErrors, formGenerateErrorsObject} from "../../../../core/form";
import {apiLanguagesGet, apiSkillsGet, apiSoftwareSearch} from "../../../../actions/metadataActions";
import {apiStringerUpdateSkills} from "../../../../actions/stringerActions";
import Modal from "../../../ui/Modal";
import Field from "../../../ui/Field";
import Checkbox from "../../../ui/Checkbox";
import Button from "../../../ui/Button";

const errorsList: any = {}

const initialValues: any = {
  rate_hour: '',
  rate_day: '',
  languages: [],
  softwares: [],
  skills: []
}

function StringerModalSkills({open, stringer, onClose, onUpdate} : any) {

  const token = useSelector(state => state.user.token)
  const [skills, setSkills] = useState<any>([])
  const [values, setValues] = useState<any>(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    loadSkills()
  }, [])

  useEffect(() => {
    setValues({
      ...values,
      rate_hour: stringer.rate_hour,
      rate_day: stringer.rate_day,
      languages: stringer.languages,
      softwares: stringer.softwares,
      skills: stringer.skills
    })
  }, [stringer])

  const loadSkills = async () => {

    const skills = await apiSkillsGet()
    // @ts-ignore
    setSkills(skills)

  }

  const changeField = (field: string, value: any) => {
    setValues({...values, [field]: value})
    setErrors({...errors, [field]: ""})
  }

  const selectSkill = (skill: any, checked: any) => {

    const skills = values.skills

    if (checked) {
      if (skills.indexOf(skill.name) === -1) {
        skills.push(skill.name)
      }
    } else {
      if (skills.indexOf(skill.name) !== -1) {
        skills.splice(skills.indexOf(skill.name), 1)
      }
    }

    changeField('skills', skills)

  }

  const submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setSubmitLoading(true);

    try {

      const apiResponse = await apiStringerUpdateSkills(
        token,
        values.rate_hour,
        values.rate_day,
        values.languages,
        values.softwares,
        values.skills
      );
      if (apiResponse.status) {
        onUpdate()
        setSubmitLoading(false)
      }

    } catch (error) {
      console.log(error);
    }

  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={(
        <div className="modal-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Skills & Pricing</span>
            <div className="modal-window-top-close" onClick={() => onClose()}/>
          </div>
          <div className="modal-window-content">
            <div className="modal-window-content-inner">
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  <Field
                    id="profileFirstName"
                    label="Rate per hour, USD"
                    type="input"
                    value={values.rate_hour}
                    onChange={(value) => changeField("rate_hour", value)}
                  />
                </div>
                <div className="modal-window-col">
                  <Field
                    id="profileFirstName"
                    label="Rate per day, USD"
                    type="input"
                    value={values.rate_day}
                    onChange={(value) => changeField("rate_day", value)}
                  />
                </div>
              </div>
              <div className="modal-window-row row">
                <Field
                  id="uploadCategories"
                  label="Languages *"
                  type="multiSelect"
                  options={{
                    onLoadElements: apiLanguagesGet,
                    placeholder: 'Select language'
                  }}
                  value={values.languages}
                  onChange={(value) => changeField("languages", value)}
                />
              </div>
              <div className="modal-window-row row">
                <Field
                  id="uploadTags"
                  label="Softwares"
                  type="multiSearch"
                  value={values.softwares}
                  onChange={(value) => changeField("softwares", value)}
                  options={{
                    placeholder: 'Start type software',
                    onSearch: apiSoftwareSearch
                  }}
                />
              </div>
              <div className="modal-window-checkboxes row">
                {skills.map((skill: any) => {
                  return (
                    <Checkbox
                      label={skill.name}
                      onChange={(checked) => {
                        selectSkill(skill, checked)
                      }}
                      checked={values.skills.indexOf(skill.name) !== -1}
                      className={'modal-window-checkbox'}
                    />
                  )
                })}
              </div>
            </div>
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <button className="modal-window-bottom-cancel-button" onClick={onClose}>Cancel</button>
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Save'}
                loading={submitLoading}
                onClick={submit}
                type={'button'}
              />
            </div>
          </div>
        </div>
      )}
    />
  )

}

export default StringerModalSkills;
