import axios from "axios";
import {API_URL} from "../core/common";
import {TableLoadElementsParams} from "../components/table/TablePage";

export const apiEmployeesGet = ({
  cancelToken,
  token,
  search,
  filters,
  offset
} : TableLoadElementsParams) => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/employee.get`, {
      cancelToken,
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export const apiEmployeeGet = (token: string, id: number) => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/employee.find?id=${id}`, {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data.employee);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiEmployeeCreate = ({
  token,
  firstName,
  lastName,
  phone,
  position,
  email,
}: {
  token: string,
  firstName: string,
  lastName: string,
  phone: string,
  position: string,
  email: string
}): Promise<{
  status: boolean,
  employee_id: number,
  error?: string
}> => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/employee.create', {
      first_name: firstName,
      last_name: lastName,
      phone,
      position,
      email
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}
