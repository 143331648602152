import React from 'react';
import {UserType} from "../../../../types/models";

export function TaskClient({client, user,}: {
  client: any
  user: UserType
}) {


  return (
    <>
      <div className="task-page-member">
        <div className="task-page-member-left">
          <img
            src={"/images/avatar.svg"}
            className="task-page-member-left-image"
          />
        </div>
        <div className="task-page-member-right">
          <div className="task-page-member-top">
            <span className="task-page-member-name">{user.first_name} {user.last_name}</span>
          </div>
          <span className="task-page-member-label">Phone: {client.contact_phone}</span>
          <span className="task-page-member-label">Organization: {client.organization}</span>
          <span className="task-page-member-label">Position: {client.position}</span>
          <span className="task-page-member-label">Country: {client.country}</span>
          <span className="task-page-member-label">Email: {user.email}</span>
        </div>
      </div>
    </>
  )

}
