import React from 'react';
import {useSelector} from "react-redux";
import {NavLink, useOutletContext} from "react-router-dom";
import './Promo.scss'

function Promo() {

  const content: any = useOutletContext();

  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const user = useSelector(state => state.user.user);

  return (
    <div className="landing-main-banner">
      <div className="landing-main-banner-inner"/>
      <div className="landing-main-banner-content">
        <div className="landing-main-banner-content-container">
          <h1 className="landing-main-banner-title">We connect content creators<br/>to global media outlets</h1>
          <div className="landing-main-banner-line"/>
          <div className="landing-main-banner-buttons">
            {isLoggedIn ? (
              <div>
                {user.role === 'stringer' && (
                  <NavLink to={'/profile'} className="landing-main-banner-button">Go to profile</NavLink>
                )}
                {user.role === 'client' && (
                  <NavLink to={'/tasks/create'} className="landing-main-banner-button">Create task</NavLink>
                )}
              </div>
            ) : (
              <>
                <button className="landing-main-banner-button" onClick={content.onOpenSignOutModal}>Become a content creator</button>
                <button className="landing-main-banner-button" onClick={content.onOpenSignOutModal}>For content buyers</button>
              </>
            )}
          </div>
          <span className="landing-main-banner-sub-title">Sell your newsworthy or viral videos to global media outlets<br/>using our platform</span>
        </div>
      </div>
    </div>
  );

}

export default Promo;
