import React, {useEffect, useState} from 'react';
import Checkbox from "../../../../components/ui/Checkbox";
import {apiTaskGetTypes} from "../../../../actions/taskActions";

function TaskTypes({selectedTypes, onChange}: {
  selectedTypes: string[],
  onChange: (values: string[]) => void
}) {

  const [types, setTypes] = useState([])

  useEffect(() => {
    loadTaskTypes();
  }, [])

  const loadTaskTypes = async () => {

    const types = await apiTaskGetTypes()
    setTypes(types)

  }

  const changeTaskType = (type: string, checked: boolean) => {

    if (checked) {
      if (selectedTypes.indexOf(type) === -1) {
        onChange([
          ...selectedTypes,
          type
        ])
      }
    } else {
      if (selectedTypes.indexOf(type) !== -1) {
        onChange(selectedTypes.filter(_type => _type !== type))
      }
    }

  }

  return (
    <div className="create-page-block-checkboxes row">
      {types.map((type: string) => {
        return (
          <Checkbox
            label={type}
            checked={selectedTypes.indexOf(type) !== -1}
            onChange={(checked) => {
              changeTaskType(type, checked)
            }}
            className="create-page-block-checkbox"
          />
        )
      })}
    </div>
  )

}

export default TaskTypes
