import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {apiLocationDetail, apiLocationSearch} from "../../actions/locationActions";
import {useOnClickOutside} from "usehooks-ts";

export type Location = {
  location_text: string,
  location_place_id: string,
  location_country: string,
  location_lat: string,
  location_lng: string
  location_city: string,
  location_state: string
}

type LocationSelectProps = {
  onChange: Function,
  initialValue: Location | null
}

const CancelToken = axios.CancelToken;
let source: any;

function LocationSelect({onChange, initialValue}: LocationSelectProps) {

  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState<any[]>([]);
  const [input, setInput] = useState("");

  const ref = useRef(null)


  const handleClickOutside = () => {

    if (elements.length > 0) {

      setElements([])
      setInput('')

    }

  }

  useOnClickOutside(ref, handleClickOutside)

  useEffect(() => {
    if (initialValue !== null) {
      setInput(initialValue.location_text)
    }
  }, [])

  const search = async (text: string) => {

    if (source) {
      source.cancel();
    }

    setInput(text);
    onChange(null)

    if (text.length > 2) {

      source = CancelToken.source();
      setLoading(true);
      let elements = await apiLocationSearch(source.token, text);
      setElements(elements);
      setLoading(false);

    } else {

      setElements([]);
      onChange(null)

    }

  }

  const select = async (element: any) => {

    setElements([]);
    setLoading(true);

    const place = await apiLocationDetail(element.place_id);

    let country = null;
    let state = null;
    let city = null;

    place.address_components.forEach((component: any) => {

      if (component.types.indexOf("country") !== -1) {
        country = component.long_name;
      }

      if (component.types.indexOf("administrative_area_level_1") !== -1) {
        state = component.long_name;
      }

      if (component.types.indexOf("locality") !== -1) {
        city = component.long_name;
      }

    })

    const lat = place.geometry.location.lat;
    const lng = place.geometry.location.lng;

    setInput(place.formatted_address);
    setLoading(false);
    onChange({
      location_text: place.formatted_address,
      location_place_id: element.place_id,
      location_country: country,
      location_lat: lat,
      location_lng: lng,
      location_state: state,
      location_city: city
    })

  }

  return (
    <div className="custom-select" ref={ref}>
      <div className="custom-select-top row">
        <input
          type="text"
          className={"custom-select-top-input"}
          placeholder={"Start typing location"}
          value={input}
          onChange={(event) => {
            search(event.target.value);
          }}
        />
        {loading && (
          <div className="custom-select-top-spinner">
            <div className="spinner"/>
          </div>
        )}
      </div>
      {
        elements.length > 0 && (
          <div className="custom-select-submenu">
            {
              elements.map(function (element) {
                return (
                  <span
                    key={element.place_id}
                    className="custom-select-submenu-element"
                    onClick={() => {
                      select(element);
                    }}
                  >
                    {element.description}
                  </span>
                )
              })
            }
          </div>
        )
      }
    </div>
  );
}

export default LocationSelect;
