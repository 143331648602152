import React from 'react';
import {Link} from "react-router-dom";

function NoAccess() {
  return (
    <div className="error-page">
     <div className="error-page-container">
       <h1 className="error-page-title">Oh, shoot</h1>
       <div className="error-page-image-area">
         <div className="error-page-image"/>
       </div>
       <span className="error-page-sub-title">Something’s wrong here...</span>
       <div className="error-page-text">
         You do not have access to this page. <br/>You can go to the <Link to={'/'}>mainpage</Link></div>
     </div>
    </div>
  );
}

export default NoAccess;
