import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {formCheckErrors, formGenerateErrorsObject} from "../../../core/form";
import useModal from "../../../hooks/useModal";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import {renderField} from "../../../fields";
import {portfolioFields} from "../../../fields/portfolio";
import {contactFields} from "../../../fields/contact";
import Button from "../../../components/ui/Button";
import {apiFormSend} from "../../../actions/userActions";

const errorsList: any = {
  firstName: 'required',
  lastName: 'required',
  email: 'email'
}

const initialValues: any = {
  firstName: '',
  lastName: '',
  email: '',
  message: ''
}

function ContactUs() {

  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [loading, setLoading] = useState(false)

  const [successModalOpen, openSuccessModal, closeSuccessModal] = useModal();

  const getFieldActions = (key: string) => {
    return {
      value: values[key],
      error: errors[key],
      onChange: (value: any) => {
        setValues({
          ...values,
          [key]: value,
        })
        setErrors({
          ...errors,
          [key]: '',
        })
      }
    }
  }

  const submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setLoading(true)
    await apiFormSend(values['firstName'], values['lastName'], values['email'], values['message'])
    openSuccessModal()

  }

  return (
    <>
      <ConfirmModal
        open={successModalOpen}
        onClose={closeSuccessModal}
        title='Success'
        message={'Your message has been sent successfully. We will contact you soon.'}
        submitButtonText='OK'
        onUpdate={() => {
          window.location.reload()
        }}
      />
      <div className="contact-page">
        <div className="contact-page-container">
          <div className="contact-page-title">Contact Us</div>
          <div className="contact-page-content">
            <div className="contact-page-left">
              <div className="contact-form">
                <div className="contact-page-row">
                  <div className="contact-page-col">
                    {renderField({
                      ...contactFields.firstName,
                      ...getFieldActions('firstName'),
                      required: true,
                    })}
                  </div>
                  <div className="contact-page-col">
                    {renderField({
                      ...contactFields.lastName,
                      ...getFieldActions('lastName'),
                      required: true,
                    })}
                  </div>
                </div>
                <div className="contact-page-element">
                  {renderField({
                    ...contactFields.email,
                    ...getFieldActions('email'),
                    required: true,
                  })}
                </div>
                <div className="contact-page-element">
                  {renderField({
                    ...contactFields.message,
                    ...getFieldActions('message'),
                    required: false
                  })}
                </div>
                <div className="contact-page-submit">
                  <Button
                    className={'contact-page-submit-button'}
                    label={'Submit'}
                    loading={loading}
                    onClick={submit}
                    type={'button'}
                  />
                </div>
              </div>
            </div>
            <div className="contact-page-right">
              <img src="/images/operator.svg" className="contact-page-image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs
