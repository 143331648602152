import React, {ReactElement} from "react";
import Field from "../components/ui/Field";

export type FieldTypes = 'input' | 'location' | 'datetime' | 'textarea' | 'multiSearch' | 'multiSelect' | 'links' | 'singleSearch'
export type CustomField = {
  id: string
  label: string
  type: FieldTypes
  options?: any
}

export const renderField = ({
  id,
  label,
  type,
  value,
  onChange,
  options,
  required,
  className,
  error,
  disabled
}: {
  id: string
  label: string
  type: FieldTypes
  value: any
  onChange: (value: any) => void
  options?: any
  required?: boolean
  className?: string
  error?: string
  disabled?: boolean
}) : ReactElement => {
  return (
    <Field
      required={required}
      className={className}
      id={id}
      label={label}
      type={type}
      error={error}
      value={value}
      onChange={onChange}
      options={{
        ...options && options,
        disabled: disabled
      }}
    />
  )
}
