import React from 'react';

function Editorial() {
  return (
    <>

    </>
  );
}

export default Editorial
