import React, {useEffect, useState} from 'react';
import Loading from "../../../../components/ui/Loading";
import {useNavigate, useParams} from "react-router-dom";
import {apiStringerFind, getStringerFromResponse} from "../../../../actions/stringerActions";
import {useSelector} from "react-redux";
import Stringer from "../../../../components/stringer/Stringer";

function StringerPage() {

  const token = useSelector(state => state.user.token)
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true)
  const [stringer, setStringer] = useState<any>(null)

  useEffect(() => {

    loadData();

  }, [])

  const loadData = async () => {

    if (id !== undefined) {

      const response = await apiStringerFind(token, id);

      setStringer(getStringerFromResponse(response))
      setLoading(false)

    }

  }

  if (loading || stringer === null) {
    return <Loading/>
  }

  return (
    <>
      <div className="video-page-top">
        <div className="video-page-top-back-icon" onClick={() => {
          navigate(-1);
        }}>
          <i className="fa fa-angle-left"/>
        </div>
        <span className="video-page-top-title">Stringers</span>
      </div>
      <Stringer stringer={stringer} editable={false}/>
    </>
  )
}

export default StringerPage
