import axios from "axios";
import {API_URL} from "../core/common";

export function apiLocationSearch(
  token: any,
  input: string
) {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + '/location.search?input=' + input, {
      cancelToken: token
    }).then(function (response) {
      resolve(response.data.places.predictions);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export function apiLocationDetail(
  place_id: string
) {

  return new Promise<any>((resolve, reject) => {

    axios.get(API_URL + '/location.detail?place_id=' + place_id).then(function (response) {
      resolve(response.data.place);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiLocationCountriesGet() {

  return new Promise<any>((resolve, reject) => {

    axios.get(`${API_URL}/countries.get`).then(function (response) {
      resolve(response.data.countries.map((country: any) => country.name));
    }).catch(function (error) {
      reject(error);
    })

  })

}
