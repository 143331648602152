import React, {useEffect, useState} from 'react';
import Panel from "./layouts/Panel";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PanelClients from "./pages/panel/clients/PanelClients";
import PanelStringers from "./pages/panel/stringers/PanelStringers";
import Site from "./layouts/Site";
import { Provider } from 'react-redux'
import {store} from './reducers/store';
import {userSession} from "./actions/userActions";
import {userSignInAction} from "./reducers/user";
import Profile from "./pages/profile/Profile";
import Loading from "./components/ui/Loading";
import Briefs from "./pages/briefs/Briefs";
import "./css/style.scss"
import NoAuth from "./layouts/NoAuth";
import Agreement from "./pages/agreement/Agreement";
import PanelBriefs from "./pages/panel/briefs/PanelBriefs";
import PageNotFound from "./pages/pagenotfound/PageNotFound";
import PanelVideos from "./pages/panel/videos/PanelVideos";
import VideoPage from "./pages/panel/videos/page/VideoPage";
import GalleryPage from "./pages/gallery/page/GalleryPage";
import Gallery from "./pages/gallery/Gallery";
import Activate from "./pages/activate/Activate";
import StringerPage from './pages/panel/stringers/page/StringerPage';
import PanelTasks from "./pages/panel/tasks/PanelTasks";
import PanelTaskCreate from "./pages/panel/tasks/create/PanelTaskCreate";
import Tasks from "./pages/tasks/Tasks";
import VideoUpload from "./pages/video/VideoUpload";
import VideoEdit from "./pages/video/VideoEdit";
import Landing from "./pages/landing/Landing";
import BriefPage from "./pages/briefs/page/BriefPage";
import PanelBriefPage from "./pages/panel/briefs/page/PanelBriefPage";
import TaskPage from "./pages/tasks/page/TaskPage";
import PanelTaskPage from "./pages/panel/tasks/page/PanelTaskPage";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";
import TaskCreate from "./pages/tasks/create/TaskCreate";
import PanelPartners from "./pages/panel/partners/PanelPartners";
import PanelEmployees from "./pages/panel/employees/PanelEmployees";
import PanelEmployeePage from './pages/panel/employees/page/PanelEmployeePage';
import Editorial from "./pages/landing/pages/Editorial";
import BecomeAStringer from "./pages/landing/pages/BecomeAStinger";
import MediaBuyers from "./pages/landing/pages/MediaBuyers";
import UgcContent from "./pages/landing/pages/UgcContent";
import Brand from "./pages/landing/pages/Brand";
import AboutUs from "./pages/landing/pages/AboutUs";
import Restore from "./pages/restore/Restore";
import PanelDownloads from "./pages/panel/downloads/PanelDownloads";
import PanelLogs from './pages/panel/logs/PanelLogs';
import ContactUs from "./pages/landing/pages/ContactUs";

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    checkSession();

  }, [])

  const checkSession = async () => {

    const session = await userSession();
    if (session === null) {
      setLoading(false);
    } else {
      store.dispatch(userSignInAction({
        user: session.user,
        token: session.token
      }));
      setLoading(false);
    }

  }

  if (loading) {
    return (
      <Loading/>
    )
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route element={<Panel/>}>

            <Route path="panel/clients" element={<PanelClients/>} />

            <Route path="panel/stringers" element={<PanelStringers/>} />
            <Route path="panel/stringers/:id" element={<StringerPage/>} />

            <Route path="panel/videos" element={<PanelVideos/>} />
            <Route path="panel/videos/:id" element={<VideoPage/>} />

            <Route path="panel/briefs" element={<PanelBriefs/>} />
            <Route path="panel/briefs/:id" element={<PanelBriefPage/>} />

            <Route path="panel/tasks" element={<PanelTasks/>} />
            <Route path="panel/tasks/create" element={<PanelTaskCreate/>} />
            <Route path="panel/tasks/:id" element={<PanelTaskPage/>} />

            <Route path="panel/partners" element={<PanelPartners/>} />

            <Route path="panel/downloads" element={<PanelDownloads/>} />
            <Route path="panel/logs" element={<PanelLogs/>} />

            <Route path="panel/employees" element={<PanelEmployees/>} />
            <Route path="panel/employee/:id" element={<PanelEmployeePage/>} />

          </Route>
          <Route element={<Site/>}>

            <Route path="activate" element={<Activate/>} />
            <Route path="restore" element={<Restore/>} />

            <Route path="profile" element={<Profile/>} />

            <Route path="briefs" element={<Briefs/>} />
            <Route path="briefs/:id" element={<BriefPage/>} />

            <Route path="tasks" element={<Tasks/>} />
            <Route path="tasks/:id" element={<TaskPage/>} />
            <Route path="tasks/create" element={<TaskCreate/>} />

            <Route path="video/upload" element={<VideoUpload/>} />
            <Route path="video/edit/:id" element={<VideoEdit/>} />

            <Route path="gallery" element={<Gallery/>} />
            <Route path="gallery/:id" element={<GalleryPage/>} />

          </Route>
          <Route element={<NoAuth/>}>
            <Route path="/" element={<Landing/>} />

            <Route path="agreement" element={<Agreement/>} />
            <Route path="terms_of_services" element={<Terms/>} />
            <Route path="privacy_policy" element={<Privacy/>} />

            <Route path="/editorial" element={<Editorial/>} />
            <Route path="/become-a-stringer" element={<BecomeAStringer/>} />
            <Route path="/media-buyers" element={<MediaBuyers/>} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/ugc-content" element={<UgcContent/>} />
            <Route path="/brand" element={<Brand/>} />

            <Route path="/contact-us" element={<ContactUs/>} />
            <Route path="/contact_us" element={<ContactUs/>} />

            <Route path="*" element={<PageNotFound/>} />

          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
