import React, {useEffect, useState} from 'react';
import {getTaskStatus} from "../../../../core/helpers";

const statuses = [
  "On Moderation",
  "Searching for a stringer",
  "Production",
  "Completed"
]

export function TaskPoints({task}: {
  task: any
}) {

  const activeStatus = getTaskStatus(task)

  if (task.status === 3 || task.status === 0) {
    return (
      <div className="task-page-points">
        <span className='task-page-point active'>Rejected</span>
      </div>
    )
  }

  return (
    <div className="task-page-points">
      {statuses.map((status: string) => <span className={`task-page-point ${status === activeStatus && 'active'}`}>{status}</span>)}
    </div>
  )

}
