import React, { useState } from 'react';
import Modal from "../ui/Modal";
import Button from "../ui/Button";

export type BaseModalProps = {
  open: boolean,
  onClose: () => void,
  onUpdate?: () => void,
}

export type ConfirmModalProps = BaseModalProps & {
  title: string,
  message: string,
  submitButtonText: string,
  cancelButtonText?: string,
  callback?: Function
}

function ConfirmModal({
  title, message, submitButtonText, cancelButtonText, callback, onClose, onUpdate, open
}: ConfirmModalProps) {

  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {

    if (callback) {
      setLoading(true)
      await callback()
      setLoading(false)
    }
    if (onUpdate) {
      onUpdate()
    } else {
      onClose()
    }

  }

   return (
     <Modal
       open={open}
       onClose={onClose}
       content={(
         <div className="modal-window confirm-window">
           <div className="modal-window-top">
             <span className="modal-window-top-title">{title}</span>
             <div className="modal-window-top-close" onClick={onClose}/>
           </div>
           <div className="modal-window-content confirm-window-content">
             <span className="confirm-window-text">{message}</span>
           </div>
           <div className="modal-window-bottom">
             <div className="modal-window-bottom-buttons">
               {cancelButtonText && <button className="modal-window-bottom-cancel-button" onClick={onClose}>{cancelButtonText}</button>}
               <Button
                 className={'modal-window-bottom-submit-button'}
                 label={submitButtonText}
                 loading={loading}
                 onClick={handleConfirm}
                 type={'button'}
               />
             </div>
           </div>
         </div>
       )}
     />
 )
}

export default ConfirmModal;
