import React from 'react';

function Privacy() {
  return (
    <div className="custom-page">
      <div className="custom-page-container">
        <h1 className="custom-page-title">Stringeshub Privacy Policy</h1>

        <div className="custom-page-content">
          <div className="custom-page-content-text">
            <p>Last updated: May 2019</p>

            <p>We&rsquo;re committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at info@stringershub.com</p>

            <p>When you visit our website https://stringershub.com, mobile application, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We hope you take some time to read through it carefully, as it is important. If there are any terms that you do not agree with, please discontinue use of our website, mobile application and our services.</p>

            <p>1. Introduction StringersHub provides an online marketplace where users can buy, license or sell video news content (the &ldquo;Content&rdquo;) through a Software as a Service (SaaS) model. At StringersHub, the privacy and security of our customers and visitors are of paramount importance. This privacy policy explains how StringersHub processes information that can be used to directly or indirectly identify an individual (&ldquo;Personal Data&rdquo;) collected through use of its website, media platform and mobile application.</p>

            <p>For the purposes of this policy, StringersHub defines the term &ldquo;User&rdquo; as a natural person or entity who has created an account with StringersHub or with whom StringersHub has an established relationship, and the term &ldquo;Visitor&rdquo; as an individual that visits our front-end website (including https://stringershub.com).</p>

            <p>StringersHub implements and maintains appropriate technical, security and organisational measures to protect Personal Data against unauthorized or unlawful processing and use, and against accidental loss, destruction, damage, theft or disclosure.</p>

            <p>2. Collection and use 2.1 Website Visitors By visiting our website, you consent to the collection and use of your Personal Data as described herein. If you do not agree with the terms set out herein, please do not visit this website. If required by applicable law, we will seek your explicit consent to process Personal Data collected on this website or volunteered by you. Kindly note that any consent will be entirely voluntary. However, if you do not grant the requested consent to the processing of your Personal Data, the use of this website may not be possible.</p>

            <p>When you visit the website, StringersHub may collect, record and analyze information from your use of the website. We may record your IP address and use cookies. StringersHub may add information collected by way of pageview activity. Furthermore, StringersHub may collect and process any Personal Data that you volunteer to us in our website&rsquo;s forms, such as when you register for events or sign up for information and newsletters. If you provide StringersHub with your social media details, StringersHub may retrieve publicly available information about you from social media.</p>

            <p>Such Personal Data may comprise your IP address, first and last name, your postal and email address, your telephone number, your job title, data for social networks, your areas of interest, interest in StringersHub services, and certain information about the company you are working for (company name and address), as well as information as to the type of relationship that exists between StringersHub and yourself.</p>

            <p>StringersHub may gather data about visits to the website, including numbers of Visitors and visits, Geo-location data, length of time spent on the site, pages clicked on or where Visitors have come.</p>

            <p>2.1.1 Purpose of processing personal data StringersHub uses the collected data to communicate with Visitors, to customize content for Visitors, to show ads on other websites to Visitors, and to improve its website by analyzing how Visitors navigate its website.</p>

            <p>2.1.2 Sharing personal data StringersHub may also share such information with service vendors or contractors in order to provide a requested service or transaction or in order to analyze the Visitor behavior on its website.</p>

            <p>2.1.3 Cookies Cookies are small pieces of information sent by a website to a Visitor&rsquo;s hard disk. Cookies cannot be used to run programs or deliver viruses to your computer. By continuing to visit the website, you agree to the placement of cookies on your device. If you choose not to accept our cookies, we cannot guarantee that your experience will be as fulfilling as it would otherwise be. We may also place cookies from third parties for functional and marketing purposes. The use of cookies is widespread and benefits the surfer.</p>

            <p>2.1.4 Links to other sites Please be aware that while visiting our site, Visitors can follow links to other sites that are beyond our sphere of influence. StringersHub is not responsible for the content or privacy policies of these other sites.</p>

            <p>2.2 Users 2.2.1. General In order to provide services to its Users, StringersHub collects certain types of data from them. This section will describe how such data is collected and used by StringersHub. This applies in addition to the disclosures in Section 2.1, applicable to all Visitors of the Site and mobile application, including Users.</p>

            <p>2.2.2 Collection of User data During a User&rsquo;s registration and subsequent use of StringersHub&rsquo;s platform or mobile application, they provide information such as name, company name, email, address, telephone, credit card number and other relevant data. This information is regularly used by StringersHub to identify the User and provide them with support, services, mailings, sales and marketing actions, billing and to meet contractual obligations. It may also be used for fraud prevention measures or pursuant to a legal or regulatory obligation.</p>

            <p>StringersHub Users can at any time access and edit, update or delete their contact details by logging in with their username and password to StringersHub&rsquo;s platform and mobile application. StringersHub Users may be able to create more Users with different privilege levels within their account. It is the responsibility of the User that creates other User accounts, to choose the level of access each User should have. Once these new Users log into StringersHub, they meet the definition of User in this policy. StringersHub will not retain User data longer than is necessary to fulfill the purposes for which it was collected or as required by applicable laws or regulations.</p>

            <p>2.2.3. International Data Transfers StringersHub offers its services in a number of regions. If you reside within European Economic Area (EEA), your personal data may be transferred to countries outside the EEA. Such transfers and related processing will take place in accordance with the European Union&rsquo;s General Data Protection Regulation. Specifically, your personal data may be transferred to the United States of America, where several of our data processors and hosting facilities (provided by Amazon Web Services) reside. Such transfers will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the European Commission. You can request a copy of these data protection agreements by contacting us at info@stringershub.com.</p>

            <p>2.2.4. Camera and Videos Our services require us to collect footage and other information from your device&rsquo;s camera and videos.</p>

            <p>3. Retention and deletion StringersHub will not retain data longer than is necessary to fulfill the purposes for which it was collected or as required by applicable laws or regulations. When a users&rsquo;s account is terminated by the User, all Personal Data collected through the platform and mobile application will be deleted, as required by applicable law.</p>

            <p>4. Acceptance of these Conditions We assume that all Visitors of our website and users of StringersHub&rsquo;s platform, and mobile application have carefully read this document and agree to its contents. If someone does not agree with any part of this Privacy Policy, they should refrain from using our website and platform. We reserve the right to change our Privacy Policy as necessity dictates. Continued use of StringersHub website and platform after having been informed of any such changes to these conditions implies acceptance of the revised Privacy Policy. This Privacy Policy is an integral part of StringersHub&rsquo;s Terms of Service.</p>

            <p>5. For Further Information If you have any further questions or requests regarding the data StringersHub collects, or how we use it, then please feel free to contact us by email at: info@stringershub.com, or in writing at: Stringershub 446 W 14th St, New York, NY 10014, 2nd floor</p>
          </div>
          <div className="custom-page-image">
            <img src="/images/operator.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
