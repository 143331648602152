import {CustomField} from "./index";

export const userFields : {
  [name: string] : CustomField
} = {
  password: {
    id: 'password',
    type: 'input',
    label: 'New password',
    options: {
      type: 'password',
    }
  },
  repeatPassword: {
    id: 'repeatPassword',
    type: 'input',
    label: 'Repeat password',
    options: {
      type: 'password',
    }
  }
}
