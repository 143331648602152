import React from 'react';
import {Link} from "react-router-dom";
import moment from "moment";
import {CLOUDFRONT_URL} from "../core/common";
import {isEmpty} from "../core/helpers";

type VideoType = {
  video: any,
  fields: string[],
  link: string
}

function Video({video, fields, link} :  VideoType) {

  return (
    <div className="video-card">
      <div className="video-card-inner">
        <Link to={link} className="video-card-image">
          <div className="video-card-image-inner">
            {!isEmpty(video.thumbPath) ? (
              <img src={`${CLOUDFRONT_URL}${video.thumbPath}`} alt="" className="video-card-thumb"/>
            ) : (
              <img src={'/images/no-preview.svg'} alt="" className="video-card-thumb"/>
            )}
          </div>
        </Link>
        <div className="video-card-content-area">
          <Link to={link} className="video-card-title">{video.title}</Link>
          <div className="video-card-content">
            {fields.indexOf('location_text') !== -1 && (
              <div className="video-card-content-element row">
                <span className="video-card-content-element-label">Location</span>
                <span className="video-card-content-element-value">{isEmpty(video.location_text) ? 'Without location' : video.location_text}</span>
              </div>
            )}
            {fields.indexOf('created_at') !== -1 && (
              <div className="video-card-content-element row">
                <span className="video-card-content-element-label">Uploaded at</span>
                <span className="video-card-content-element-value single">{moment(video.created_at).format("DD/MM/YYYY")}</span>
              </div>
            )}
            {fields.indexOf('filming_date') !== -1 && (
              <div className="video-card-content-element row">
                <span className="video-card-content-element-label">Filming date</span>
                <span className="video-card-content-element-value single">{moment(video.filming_date).format("DD/MM/YYYY")}</span>
              </div>
            )}
            {fields.indexOf('status') !== -1 && (
              <div className="video-card-content-element row">
                <span className="video-card-content-element-label">Status</span>
                <span className="video-card-content-element-value single">
                  {video.status === 0 && 'Declined'}
                  {video.status === 1 && 'On moderation'}
                  {video.status === 2 && 'In Gallery'}
                  {video.status === 3 && 'On Hold'}
                </span>
              </div>
            )}
          </div>
          <div className="video-card-bottom row">
            <div className="video-card-files-num">
              <span className="video-card-files-num-icon"/>
              <span className="video-card-files-num-value">{video.files.length}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
