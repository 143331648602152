import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {formGenerateErrorsObject} from "../../../../core/form";
import Modal from "../../../../components/ui/Modal";
import moment from "moment";
import Field from "../../../../components/ui/Field";
import Button from "../../../../components/ui/Button";
import {useNavigate} from "react-router-dom";
import {apiBriefCreate} from "../../../../actions/briefActions";

const errorsList = {
  title: 'required',
  description: 'required',
}

const initialValues = {
  title: '',
  description: '',
  location: null,
  startDate: moment().toDate(),
  deadline: moment().add(1, 'days').toDate(),
}

function BriefCreateModal({onClose, open}: any) {

  const token = useSelector(state => state.user.token)

  const navigate = useNavigate()

  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [submitLoading, setSubmitLoading] = useState(false)

  const changeField = (field: string, value: any) => {
    setValues({...values, [field]: value})
    setErrors({...errors, [field]: ""})
  }

  const generateDescription = (title: string) => {
    return `We're looking for videos linked with the following topic: '${title}'.  

If you've already filmed this task/story, please upload it directly to our gallery. UGC task will be canceled the next day after this event.      

If you have any videos on this topic please load them to our platform. You’ll earn money only if any of our media clients buy it.`;
  }

  const submit = async () => {

    setSubmitLoading(true)
    const response = await apiBriefCreate({
      token,
      ...values
    })
    onClose()
    navigate(`/panel/briefs/${response.event_id}`)

  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={(
        <div className="modal-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Create brief</span>
            <div className="modal-window-top-close" onClick={() => onClose()}/>
          </div>
          <div className="modal-window-content">
            <div className="modal-window-content-inner">
              <div className="modal-window-row row">
                <Field
                  id="briefTitle"
                  label="Title *"
                  type="input"
                  error={errors.title}
                  value={values.title}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      title: value,
                      description: generateDescription(value)
                    })
                    setErrors({
                      ...errors,
                      title: '',
                      description: ''
                    })
                  }}
                />
              </div>
              <div className="modal-window-row row">
                <Field
                  id="briefLocation"
                  label="Location"
                  type="location"
                  value={values.location}
                  onChange={(value) => changeField("location", value)}
                />
              </div>
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  <Field
                    id="briefStartDate"
                    label="Event Date"
                    type="datetime"
                    error={errors.startDate}
                    value={values.startDate}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        startDate: value,
                        deadline: moment(value).add(1, 'days').toDate()
                      })
                    }}
                  />
                </div>
                <div className="modal-window-col">
                  <Field
                    id="briefDeadline"
                    label="Deadline"
                    type="datetime"
                    value={values.deadline}
                    onChange={(value) => changeField("deadline", value)}
                  />
                </div>
              </div>
              <div className="modal-window-row row">
                <Field
                  id="briefDescription"
                  label="Description *"
                  type="textarea"
                  options={{
                    minRows: 6
                  }}
                  error={errors.description}
                  value={values.description}
                  onChange={(value) => changeField("description", value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <button className="modal-window-bottom-cancel-button" onClick={onClose}>Cancel</button>
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Save'}
                loading={submitLoading}
                onClick={submit}
                type={'button'}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
}

export default BriefCreateModal;
