import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Loading from "../../../components/ui/Loading";
import {apiStringerLoadProfile, getStringerFromResponse} from "../../../actions/stringerActions";
import Stringer from "../../../components/stringer/Stringer";

function ProfileStringer() {

  const token = useSelector(state => state.user.token)

  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<any>(null)

  useEffect(() => {

    loadProfile();

  }, [])

  const loadProfile = async () => {

    setLoading(true)
    const response = await apiStringerLoadProfile(token);
    if (response !== null) {
      setProfile(getStringerFromResponse(response))
      setLoading(false)
    }

  }


  if (loading || profile === null || profile.user === null) {
    return <Loading/>
  }

  return  (
    <Stringer
      editable={true}
      stringer={profile}
      onUpdate={() => {
        loadProfile()
      }}
    />
  )

}

export default ProfileStringer;
