import axios from "axios";
import {API_URL} from "../core/common";

export function apiCategoriesGet() {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + '/categories.get').then(function (response) {
      resolve(response.data.categories);
    }).catch(function (error) {
      reject(error);
    })

  })

}
