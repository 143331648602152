import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {formCheckErrors, formGenerateErrorsObject} from "../../core/form";
import moment from "moment";
import Button from "../../components/ui/Button";
import VideoFiles from "../../components/ui/VideoFiles";
import {apiVideoEdit, apiVideoGet} from "../../actions/videoActions";
import {renderField} from "../../fields";
import { videoFields } from '../../fields/video';
import {getDateFromString} from "../../core/helpers";
import Loading from "../../components/ui/Loading";

const errorsList = {
  title: 'required',
  description: 'required',
  location: 'required',
  filmingDate: 'required',
}

const initialValues = {
  title: '',
  description: '',
  location: null,
  filmingDate: moment().toDate(),
  categories: [],
  tags: []
}

function VideoEdit() {

  const token = useSelector(state => state.user.token)
  const user = useSelector(state => state.user.user)
  const navigate = useNavigate();
  const { id } = useParams();

  const [files, setFiles] = useState<any>([])
  const [values, setValues] = useState<any>(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [submitLoading, setSubmitLoading] = useState(false)
  const [activeFileId, setActiveFileId] = useState<any>(null)
  const [loading, setLoading] = useState(true)


  const getFieldActions = (key: string) => {
    return {
      value: values[key],
      error: errors[key],
      onChange: (value: any) => {
        setValues({
          ...values,
          [key]: value,
        })
        setErrors({
          ...errors,
          [key]: '',
        })
      }
    }
  }

  useEffect(() => {

    loadData();

  }, [])

  const loadData = async () => {

    if (id !== undefined) {

      const response : any = await apiVideoGet(token, id.toString());
      if (response.status) {
        const video = response.video;
        const files = response.files.map((file: any) => {
          return {
            percent: 100,
            completed: true,
            downloadLink: file.originalPath,
            thumbLink: file.thumbPath,
            id: file.id,
            size: file.size,
            filename: file.filename,
            num: file.num,
            deleted: file.deleted,
            status: file.status
          }
        })
        setValues({
          ...values,
          id: video.id,
          title: video.title,
          description: video.description,
          location: video.location_place_id !== null ? {
            location_text: video.location_text,
            location_place_id: video.location_place_id,
            location_lat: video.location_lat,
            location_lng: video.location_lng,
            location_country: video.location_country,
            location_city: video.location_city,
          } : null,
          filmingDate: getDateFromString(video.filming_date),
          categories: response.categories,
          tags: response.tags,
          status: video.status,
        })
        setFiles([
          ...files
        ])
        if (files.length > 0) {
          setActiveFileId(files[0].id)
        }
        setLoading(false)
      }

    }

  }

  const submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setSubmitLoading(true);

    try {

      const apiResponse = await apiVideoEdit(
        token,
        values.id,
        values.title,
        values.description,
        values.location,
        values.filmingDate,
        files,
        values.categories.join(','),
        values.tags.join(',')
      );
      if (apiResponse.status) {
        if (user.role === 'stringer') {
          navigate(`/profile?tab=videos`)
        }
        if (user.role === 'admin') {
          navigate(`/panel/videos/${apiResponse.video_id}`)
        }
      }

    } catch (error) {
      console.log(error);
    }

  }

  if (loading) {
    return <Loading/>
  }

  // @ts-ignore
  return (
    <>
      <div className="video-page-top">
        <div onClick={() => {
          navigate(-1)
        }} className="video-page-top-back-icon">
          <i className="fa fa-angle-left"/>
        </div>
        <span className="video-page-top-title">Edit video</span>
        <div className="video-page-top-right">

        </div>
      </div>
      <div className="create-page-row row" style={{marginTop: '20px'}}>
        <div className="create-page-block">
          <div className="create-page-block-inner">
            {renderField({
              ...videoFields.title,
              ...getFieldActions('title'),
              className: "create-page-block-element",
              required: true,
            })}
            <div className="create-page-block-row row">
              <div className="create-page-block-row-col large">
                {renderField({
                  ...videoFields.location,
                  ...getFieldActions('location'),
                  className: "create-page-block-element",
                  required: true,
                })}
              </div>
              <div className="create-page-block-row-col small">
                {renderField({
                  ...videoFields.filmingDate,
                  ...getFieldActions('filmingDate'),
                  className: "create-page-block-element",
                  required: true,
                })}
              </div>
            </div>
            {renderField({
              ...videoFields.categories,
              ...getFieldActions('categories'),
              className: "create-page-block-element",
              required: true,
            })}
            {renderField({
              ...videoFields.tags,
              ...getFieldActions('tags'),
              className: "create-page-block-element",
            })}
            {renderField({
              ...videoFields.description,
              ...getFieldActions('description'),
              className: "create-page-block-element",
              required: true
            })}
            <div style={{height: '20px', width: '1px'}}/>
          </div>
        </div>
        <div className="create-page-block">
          <VideoFiles
            videoId={id}
            onChangeFiles={(files: any) => setFiles(files)}
            initialFiles={files}
            clickable={false}
            moderation={false}
          />
        </div>
      </div>
      <div className="bottom-area">
        <div className="bottom-area-inner">
          <div className="bottom-area-right">
            <Button
              className={'blue bottom-area-submit-button'}
              label={'Submit'}
              loading={submitLoading}
              onClick={submit}
              type={'button'}
            />
          </div>
        </div>
      </div>
    </>
  )


}

export default VideoEdit;
