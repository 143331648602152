import React from 'react';
import {StringerType} from "../../../../types/models";
import {CLOUDFRONT_URL} from "../../../../core/common";
import {NavLink} from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import DeclineExecutorModal from "../../../../components/modals/DeclineExecutorModal";

export function TaskExecutor({stringer, taskId}: {
  stringer: StringerType
  taskId: number
}) {

  const [declineExecutorModalOpen, openDeclineExecutorModal, closeDeclineExecutorModal] = useModal()

  return (
    <>
      <DeclineExecutorModal
        open={declineExecutorModalOpen}
        onClose={closeDeclineExecutorModal}
        taskId={taskId}
      />
      <div className="task-page-member">
        <div className="task-page-member-left">
          <img
            src={stringer.user.image !== null ? `${CLOUDFRONT_URL}avatars/${stringer.user.image}` : "/images/avatar.svg"}
            className="task-page-member-left-image"
          />
        </div>
        <div className="task-page-member-right">
          <div className="task-page-member-top">
            <NavLink to={`/panel/stringers/${stringer.id}`} className="task-page-member-name">{stringer.user.first_name} {stringer.user.last_name}</NavLink>
            <div className="task-page-member-icons">
              <div className="task-page-member-icon" onClick={openDeclineExecutorModal}>
                <img className="task-page-member-icon-image" src="/images/trash-icon.svg" alt=""/>
              </div>
            </div>
          </div>
          <span className="task-page-member-label">Phone: {stringer.phone}</span>
          <span className="task-page-member-label">Email: {stringer.user.email}</span>
        </div>
      </div>
    </>
  )

}
