import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Loading from "../../../../components/ui/Loading";
import {apiTaskGet} from "../../../../actions/taskActions";
import Tabs from "../../../../components/ui/Tabs";
import TaskPageGeneralInfo from "./components/TaskPageGeneralInfo";
import {TaskPoints} from "../components/TaskPoints";
import StringersWithSend from "../../../../components/stringer/StringersWithSend";
import {StringerType} from "../../../../types/models";

function PanelTaskPage() {

  const token = useSelector(state => state.user.token)
  const { id } = useParams();

  const [task, setTask] = useState<any>(null)
  const [executor, setExecutor] = useState<StringerType | null>(null)
  const [proposes, setProposes] = useState<any>([])

  const [loading, setLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState('General Info');

  useEffect(() => {

    loadData();

  }, [])

  const loadData = async () => {

    if (id !== undefined) {

      const response = await apiTaskGet(token, id);
      if (response.task !== null) {
        setTask(response.task)
        setProposes(response.proposes)
        setExecutor(response.executor)
        setLoading(false)
      }


    }

  }

  if (loading || task === null) {
    return <Loading/>
  }

  const disabledTabs: string[] = []
  if (task.stringer_user_id !== null) {
    disabledTabs.push('Stringers')
  }

  return (
    <div className="task-page-inner">
      <div className="task-page-top">
        <div className="task-page-top-content">
          <img src="/images/test/static-map.png" alt="" className="task-page-top-map"/>
          <div className="task-page-top-info">
            <span className="task-page-top-info-name">{task.title}</span>
            {task.location_text && (
              <span className="task-page-top-info-location"><i className="fa fa-map-marker"/> {task.location_text}</span>
            )}
          </div>
          <Tabs
            className={'task-tabs'}
            tabs={['General Info', 'Stringers']}
            disabledTabs={disabledTabs}
            currentTab={currentTab}
            onChange={(tab) => setCurrentTab(tab)}
          />
          <TaskPoints task={task}/>
        </div>
      </div>
      <div className="task-page-content">
        {currentTab === 'General Info' && (
          <TaskPageGeneralInfo
            task={task}
            executor={executor}
            onChangeTaskStatus={status => {
              setTask((task: any) => {
                return {
                  ...task,
                  status
                }
              })
            }}
          />
        )}
        {currentTab === 'Stringers' && (
          <StringersWithSend
            obj={task}
            type={'task'}
            proposes={proposes}
            onChangeProposes={(_proposes) => {
              setProposes(_proposes)
            }}
            initialLocation={{
              country: task.location_country,
              state: task.location_state,
              city: task.location_city
            }}
          />
        )}
      </div>
    </div>
  )

}

export default PanelTaskPage;
