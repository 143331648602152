import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {formCheckErrors, formGenerateErrorsObject} from "../../../../core/form";
import {apiStringerUpdateEquipments} from "../../../../actions/stringerActions";
import Modal from "../../../ui/Modal";
import Field from "../../../ui/Field";
import {apiCameraSearch, apiLenSearch, apiMicrophoneSearch} from "../../../../actions/metadataActions";
import Checkbox from "../../../ui/Checkbox";
import Button from "../../../ui/Button";

const errorsList: any = {}

const initialValues: any = {
  cameras: [],
  microphones: [],
  lens: [],
  stabilizer: false,
  drone: false,
  car_availability: false
}

function StringerModalEquipment({open, stringer, onClose, onUpdate} : any) {

  const token = useSelector(state => state.user.token)
  const [values, setValues] = useState<any>(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    setValues({
      ...values,
      cameras: stringer.cameras,
      microphones: stringer.microphones,
      lens: stringer.lens,
      stabilizer: stringer.stabilizer,
      drone: stringer.drone,
      car_availability: stringer.car_availability
    })
  }, [stringer])

  const changeField = (field: string, value: any) => {
    setValues({...values, [field]: value})
    setErrors({...errors, [field]: ""})
  }

  const submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setSubmitLoading(true);

    try {
      const apiResponse = await apiStringerUpdateEquipments(
        token,
        values.cameras,
        values.microphones,
        values.lens,
        values.stabilizer,
        values.drone,
        values.car_availability
      );
      if (apiResponse.status) {
        onUpdate()
        setSubmitLoading(false)
      }

    } catch (error) {
      console.log(error);
    }

  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={(
        <div className="modal-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Equipments information</span>
            <div className="modal-window-top-close" onClick={() => onClose()}/>
          </div>
          <div className="modal-window-content">
            <div className="modal-window-content-inner">
              <div className="modal-window-row row">
                <Field
                  id="profileCameras"
                  label="Cameras"
                  type="multiSearch"
                  value={values.cameras}
                  onChange={(value) => changeField("cameras", value)}
                  options={{
                    onSearch: apiCameraSearch,
                    placeholder: 'Start type camera'
                  }}
                />
              </div>
              <div className="modal-window-row row">
                <Field
                  id="profileMicrophones"
                  label="Microphones"
                  type="multiSearch"
                  value={values.microphones}
                  onChange={(value) => changeField("microphones", value)}
                  options={{
                    onSearch: apiMicrophoneSearch,
                    placeholder: 'Start type microphone'
                  }}
                />
              </div>
              <div className="modal-window-row row">
                <Field
                  id="profileLens"
                  label="Lens"
                  type="multiSearch"
                  value={values.lens}
                  onChange={(value) => changeField("lens", value)}
                  options={{
                    onSearch: apiLenSearch,
                    placeholder: 'Start type lens'
                  }}
                />
              </div>
              <div className="modal-window-checkboxes row">
                <Checkbox
                  label={'Stabilizer'}
                  onChange={(checked) => {
                    changeField('stabilizer', checked)
                  }}
                  checked={values.stabilizer}
                  className={'modal-window-checkbox'}
                />
                <Checkbox
                  label={'Drone'}
                  onChange={(checked) => {
                    changeField('drone', checked)
                  }}
                  checked={values.drone}
                  className={'modal-window-checkbox'}
                />
                <Checkbox
                  label={'Car availability'}
                  onChange={(checked) => {
                    changeField('car_availability', checked)
                  }}
                  checked={values.car_availability}
                  className={'modal-window-checkbox'}
                />
              </div>
            </div>
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <button className="modal-window-bottom-cancel-button" onClick={onClose}>Cancel</button>
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Save'}
                loading={submitLoading}
                onClick={submit}
                type={'button'}
              />
            </div>
          </div>
        </div>
      )}
    />
  )

}

export default StringerModalEquipment;
