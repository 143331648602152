import React from 'react';

function Loading() {

  return (
    <div className="lds-ripple">
      <div/>
      <div/>
    </div>
  );
}

export default Loading;
