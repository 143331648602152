import React, {useEffect, useState} from 'react';
import Modal from "../../../ui/Modal";
import {
  apiStringerProRequest,
  stringerCheckEquipments,
  stringerCheckGeneralInfo,
  stringerCheckSkills
} from "../../../../actions/stringerActions";
import Button from "../../../ui/Button";
import {useSelector} from "react-redux";

function StringerModalPro({open, stringer, onClose, onUpdate, portfoliosCount} : any) {

  const token = useSelector(state => state.user.token)
  const [loading, setLoading] = useState(false)

  const generalInfoFilled = stringerCheckGeneralInfo(stringer)
  const equipmentInformationFilled = stringerCheckEquipments(stringer)
  const skillsInformationFilled = stringerCheckSkills(stringer)

  const submit = async () => {

    setLoading(true)
    await apiStringerProRequest(token)
    onUpdate()

  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={(
        <div className="modal-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Apply for a PRO</span>
            <div className="modal-window-top-close" onClick={() => onClose()}/>
          </div>
          <div className="modal-window-content">
            <div className="pro-modal-window-icon"/>
            <div className="pro-modal-window-elements">
              <div className="pro-modal-window-element">
                <div className={`pro-modal-window-element-icon ${generalInfoFilled && 'completed'}`}/>
                <span className="pro-modal-window-element-label">General information filled</span>
              </div>
              <div className="pro-modal-window-element">
                <div className={`pro-modal-window-element-icon ${equipmentInformationFilled && 'completed'}`}/>
                <span className="pro-modal-window-element-label">Equipment information filled</span>
              </div>
              <div className="pro-modal-window-element">
                <div className={`pro-modal-window-element-icon ${skillsInformationFilled && 'completed'}`}/>
                <span className="pro-modal-window-element-label">Skills & Pricing information filled</span>
              </div>
              <div className="pro-modal-window-element">
                <div className="pro-modal-window-element-icon completed"/>
                <span className="pro-modal-window-element-label">Added portfolio</span>
              </div>
            </div>
            <span className="pro-modal-window-text">We will review your request within 1-2 working days</span>
            {stringer.pro_moderation === 1 && (
              <span className="pro-modal-window-status">On moderation</span>
            )}
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <button className="modal-window-bottom-cancel-button" onClick={onClose}>Cancel</button>
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Submit'}
                loading={loading}
                onClick={submit}
                type={'button'}
              />
            </div>
          </div>
        </div>
      )}
    />
  )

}

export default StringerModalPro
