import React, {useEffect, useRef, useState} from 'react';
import {useOnClickOutside} from "usehooks-ts";

function MultiSelect({onChangeSelectedElements, selectedElements, onLoadElements, elements: initialElements, placeholder}: any) {

  const [open, setOpen] = useState<boolean>(false)
  const [elements, setElements] = useState<any>([])
  const [loading, setLoading] = useState(false);

  const ref = useRef(null)

  const handleClickOutside = () => {
    setOpen(false)
  }

  useOnClickOutside(ref, handleClickOutside)

  useEffect(() => {

    if (Array.isArray(initialElements)) {
      setElements(initialElements)
    }

    if (onLoadElements) {
      loadElements()
    }

  }, [])

  const loadElements = async () => {

    setLoading(true)
    const elements = await onLoadElements()
    setElements(elements)
    setLoading(false)

  }

  const select = (element: any) => {

      setOpen(false)
      if (selectedElements.indexOf(element) === -1) {
        selectedElements.push(element)
      }
      onChangeSelectedElements(selectedElements)

  }

  const deleteElement = (element: any) => {

    if (selectedElements.indexOf(element) !== -1) {
      selectedElements.splice(selectedElements.indexOf(element), 1)
    }
    onChangeSelectedElements(selectedElements)

  }

  return (
    <div className="custom-select-select" ref={ref}>
      <div className="custom-select-top multi row">
        {selectedElements.map((element: any) => {
          return (
            <div className="custom-select-tip">
              <span className="custom-select-tip-label">{element}</span>
              <span className="custom-select-tip-close" onClick={() => {
                deleteElement(element)
              }}>x</span>
            </div>
          )
        })}
        {loading && (
          <div className="custom-select-top-spinner" style={{right: 28}}>
            <div className="spinner"/>
          </div>
        )}
        <span className="custom-select-top-label" onClick={() => {
          setOpen(!open)
        }}>{placeholder}</span>
        <img src="/images/arrow.svg" className="custom-select-top-arrow" onClick={() => {
          setOpen(!open)
        }}/>
      </div>
      {
        elements.length > 0 && open && (
          <div className="custom-select-submenu">
            {
              elements.map(function (element: any) {
                if (selectedElements.indexOf(element) === -1) {
                  return (
                    <span
                      key={element.place_id}
                      className="custom-select-submenu-element"
                      onClick={() => {
                        select(element);
                      }}
                    >
                    {element}
                  </span>
                  )
                }
              })
            }
          </div>
        )
      }
    </div>
  );
}

export default MultiSelect;
