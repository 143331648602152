import {CustomField} from "./index";

export const employeeFields : {
  [name: string] : CustomField
} = {
  firstName: {
    id: 'firstName',
    label: 'First Name',
    type: 'input',
  },
  lastName: {
    id: 'lastName',
    label: 'Last Name',
    type: 'input',
  },
  phone: {
    id: 'phone',
    label: 'Phone',
    type: 'input',
  },
  position: {
    id: 'position',
    label: 'Position',
    type: 'input',
  },
  email: {
    id: 'email',
    label: 'Email',
    type: 'input',
  },
  password: {
    id: 'password',
    label: 'Password',
    type: 'input',
  },
}
