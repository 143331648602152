import React from 'react';
import {TableAction} from "./TablePage";

function TableActions({selectedElements, onClear, actions, onSelect}: {
  selectedElements: number[],
  actions: TableAction[],
  onClear: () => void,
  onSelect: (action: TableAction) => void
}) {

  if (selectedElements.length > 0) {
    return (
      <div className="table-page-dop-row row">
        <span className="table-page-selected-text"><b>{selectedElements.length}</b> members selected</span>
        <span className="table-page-selected-clear-link" onClick={onClear}>(clear)</span>
        <div className="table-page-selected-actions">
          {actions.map(action => {
            if (action.single && selectedElements.length > 1) {
              return <span/>
            }
            return (
              <span className="table-page-selected-action" onClick={() => onSelect(action)}>
                {action.label} {!action.single && 'selected'}
                {action.icon === 'delete' &&  <img src="/images/action-delete.svg" className="table-page-selected-action-icon"/>}
                {action.icon === 'send' &&  <i className="fa fa-envelope table-page-selected-action-icon"/>}
                {action.icon === 'user' &&  <i className="fa fa-user table-page-selected-action-icon"/>}
              </span>
            )
          })}
        </div>
      </div>
    );
  }

  return <div/>

}

export default TableActions;
