import React, {useEffect, useState} from 'react';
import StringerModalPortfolioCreate from "./modals/StringerModalPortfolioCreate";
import StringerModalPortfolio from "./modals/StringerModalPortfolio";
import {apiPortfoliosGet} from "../../../actions/portfolioActions";
import {useSelector} from "react-redux";
import {Portfolio} from "../../../types/models";
import moment from "moment";

const responseToPortfolios = (response: any) : Portfolio[] => {

  return response.map((element: any) => {
    return {
      id: element.id,
      title: element.title,
      description: element.description,
      client: element.client,
      links: element.links.map((link: any) => link.link),
      filmingDate: element.filming_date
    }
  })

}

function StringerPortfolio({stringer, editable} : any) {

  const token = useSelector(state => state.user.token)

  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [viewModalObj, setViewModalObj] = useState<Portfolio | null>(null)

  const [portfolios, setPortfolios] = useState<Portfolio[]>([])

  useEffect(() => {
    loadPortfolios()
  }, [])

  const loadPortfolios = async () => {

    const portfolios = await apiPortfoliosGet(token)
    setPortfolios(responseToPortfolios(portfolios))

  }

  return (
    <>
      <StringerModalPortfolioCreate
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onUpdate={() => {
          setCreateModalOpen(false)
          loadPortfolios()
        }}
        stringer={stringer}
      />
      <StringerModalPortfolio
        open={viewModalObj !== null}
        onClose={() => setViewModalObj(null)}
        onUpdate={() => {

        }}
        portfolio={viewModalObj}
      />
      <div className="table-page stringer-page-table">
        <div className="stringer-page-table-top">
          <span className="stringer-page-table-title">Portfolio</span>
          <button className="stringer-page-table-button" onClick={() => {
            setCreateModalOpen(true)
          }}>Add project <span>+</span></button>
        </div>
        <table>
          <tr>
            <th style={{width: '30px'}}/>
            <th>
              <span>Title</span>
            </th>
            <th style={{width: '110px'}}>
              <span>Filming date</span>
            </th>
            <th style={{width: '140px'}}>
              <span>Client</span>
            </th>
            <th style={{width: '16px'}}/>
          </tr>
          {portfolios.map(portfolio => {
            return (
              <tr>
                <td/>
                <td>
                  <span
                    onClick={() => {
                      setViewModalObj(portfolio)
                    }}
                    className="table-page-text"
                    style={{cursor: 'pointer'}}
                  >
                    {portfolio.title}
                  </span>
                </td>
                <td>
                  <span className="table-page-text">{moment(portfolio.filmingDate).format('DD/MM/YYYY')}</span>
                </td>
                <td>
                  <span className="table-page-text">{portfolio.client}</span>
                </td>
                <td/>
              </tr>
            )
          })}
        </table>
      </div>
    </>
  );
}

export default StringerPortfolio
