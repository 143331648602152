import axios from "axios";
import {API_URL} from "../core/common";
import {Location} from "../components/ui/LocationSelect";
import moment from "moment";
import {TableLoadElementsParams} from "../components/table/TablePage";

export const apiBriefCreate = ({
  token,
  title,
  description,
  location,
  startDate,
  deadline
}: {
  token: string,
  title: string,
  description: string,
  location: Location | null,
  startDate: Date | null,
  deadline: Date | null
}): Promise<{
  status: boolean,
  event_id: number
}> => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/event.create', {
      title,
      description,
      location_text: location?.location_text,
      location_place_id: location?.location_place_id,
      location_lat: location?.location_lat,
      location_lng: location?.location_lng,
      location_country: location?.location_country,
      location_state: location?.location_state,
      location_city: location?.location_city,
      start_date: startDate === null ? null : moment(startDate).format("YYYY-MM-DD"),
      deadline: deadline === null ? null : moment(deadline).format("YYYY-MM-DD")
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiBriefUpdate = (
  token: string,
  id: string,
  title: string,
  description: string,
  location: Location | null,
  startDate: Date | null,
  deadline: Date | null
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/event.update', {
      id,
      title,
      description,
      location_text: location?.location_text,
      location_place_id: location?.location_place_id,
      location_lat: location?.location_lat,
      location_lng: location?.location_lng,
      location_country: location?.location_country,
      location_state: location?.location_state,
      location_city: location?.location_city,
      start_date: startDate === null ? null : moment(startDate).format("YYYY-MM-DD"),
      deadline: deadline === null ? null : moment(deadline).format("YYYY-MM-DD")
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiBriefGet = (token: string, id: any): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/event.get?id=${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiBriefsGet = ({
    cancelToken,
    token,
    search,
    filters,
    offset
} : TableLoadElementsParams) => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/events.get?offset=${offset}&limit=${100}&search=${search}&filters=${filters}`, {
      cancelToken,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export const apiBriefsGetPublic = ({limit = 50, offset = 0, filters = ''}) => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/events.get_public?limit=${limit}&offset=${offset}&filters=${filters}`, {
      headers: {
        Accept: 'application/json',
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiBriefsFindPublic = (hash: string) => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/event.get_public?hash=${hash}`, {
      headers: {
        Accept: 'application/json',
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiBriefInviteStringers = (
  token: string,
  id: number,
  stringers: number[]
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/event.invite_stringers', {
      event_id: id,
      stringers: stringers.join(",")
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiBriefClose = (
  token: string,
  ids: number[],
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/event.close', {
      event_ids: ids.join(','),
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiBriefGetCountries() {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + '/event.get_countries').then(function (response) {
      resolve(response.data.countries);
    }).catch(function (error) {
      reject(error);
    })

  })

}
