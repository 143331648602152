import axios from "axios";
import {API_URL} from "../core/common";
import {Location} from "../components/ui/LocationSelect";
import {TableLoadElementsParams} from "../components/table/TablePage";

export const apiStringerRegister = (
  token: string,
  firstName: string,
  lastName: string,
  phone: string,
  location: Location | null,
  aboutMe: string
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/stringer.register', {
      first_name: firstName,
      last_name: lastName,
      phone,
      location_text: location?.location_text,
      location_place_id: location?.location_place_id,
      location_lat: location?.location_lat,
      location_lng: location?.location_lng,
      location_country: location?.location_country,
      about_me: aboutMe
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiStringerLoadProfile = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + '/stringer.get_profile', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiStringerFind = (token: string, id: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/stringer.find?id=${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function stringerCheckGeneralInfo(profile: any) {

  let show = false;

  if (profile.about_me !== null) {
    if (profile.about_me.trim().length > 0) {
      show = true;
    }
  }

  if (profile.user.contacts.length > 0) {
    show = true;
  }

  return show;

}

export function stringerCheckSkills(profile: any) {

  let show = false;

  if (
    profile.rate_hour !== null ||
    profile.rate_day !== null ||
    profile.languages.length > 0 ||
    profile.softwares.length > 0 ||
    profile.skills.length > 0
  ) {
    show = true;
  }

  return show;

}

export function stringerCheckEquipments(profile: any) {

  let show = false;

  if (
    profile.cameras.length > 0 ||
    profile.microphones.length > 0 ||
    profile.lens.length > 0 ||
    profile.car_availability === 1 ||
    profile.stabilizer === 1||
    profile.drone === 1
  ) {
    show = true;
  }

  return show;

}

export const apiStringerUpdateGeneralInformation = (
  token: string,
  firstName: string,
  lastName: string,
  phone: string,
  location: Location | null,
  aboutMe: string,
  contacts: any = []
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/stringer.update_general_information', {
      first_name: firstName,
      last_name: lastName,
      phone,
      location_text: location?.location_text,
      location_place_id: location?.location_place_id,
      location_lat: location?.location_lat,
      location_lng: location?.location_lng,
      location_country: location?.location_country,
      about_me: aboutMe,
      contacts: JSON.stringify(contacts.filter((contact: any) => contact.value.length > 0).map((contact: any) => {
        return {
          contact_id: contact.contact.id,
          value: contact.value
        }
      }))
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiStringerUpdateEquipments = (
  token: string,
  cameras: string[],
  microphones: string[],
  lens: string[],
  stabilizer: boolean,
  drone: boolean,
  car_availability: boolean
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/stringer.update_equipments', {
      cameras: cameras.length > 0 ? cameras.join(',') : '',
      microphones: microphones.length > 0 ? microphones.join(',') : '',
      lens: lens.length > 0 ? lens.join(',') : '',
      stabilizer: +stabilizer,
      drone: +drone,
      car_availability: +car_availability
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiStringerUpdateSkills = (
  token: string,
  rate_hour: string,
  rate_day: string,
  languages: string[],
  softwares: string[],
  skills: string[]
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/stringer.update_skills', {
      rate_hour,
      rate_day,
      languages: languages.join(','),
      softwares: softwares.join(','),
      skills: skills.join(',')
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiStringersGet(
  {
    cancelToken,
    token,
    search,
    filters,
    offset
  } : TableLoadElementsParams
) {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/stringers.get?offset=${offset}&limit=${100}&search=${search}&filters=${filters}` , {
      cancelToken,
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export const getStringerFromResponse = (response: any) => {

  return {
    ...response.stringer,
    messengers: response.stringer.user.contacts.map((contact: any) => {
      return {
        value: contact.pivot.value,
        contact_id: contact.pivot.contact_id,
        contact: {
          name: contact.name,
          id: contact.id,
          icon: contact.icon
        }
      }
    }),
    cameras: response.stringer.cameras.map((camera: any) => {
      return camera.name
    }),
    lens: response.stringer.lens.map((len: any) => {
      return len.name
    }),
    microphones: response.stringer.microphones.map((microphone: any) => {
      return microphone.name
    }),
    languages: response.stringer.languages.map((language: any) => {
      return language.name
    }),
    softwares: response.stringer.softwares.map((software: any) => {
      return software.name
    }),
    skills: response.stringer.skills.map((skill: any) => {
      return skill.name
    }),
    stabilizer: Boolean(response.stringer.stabilizer),
    drone: Boolean(response.stringer.drone),
    car_availability: Boolean(response.stringer.car_availability)
  }

}

export function apiStringerCountrySearch(cancelToken: any, input: string) {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + `/stringer.search_countries?text=${input}`, {
      cancelToken
    }).then(function (response) {
      resolve(response.data.countries);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export function apiStringerStateSearch(cancelToken: any, input: string) {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + `/stringer.search_states?text=${input}`, {
      cancelToken
    }).then(function (response) {
      resolve(response.data.states);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export function apiStringerCitiesSearch(cancelToken: any, input: string) {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + `/stringer.search_cities?text=${input}`, {
      cancelToken
    }).then(function (response) {
      resolve(response.data.cities);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export function apiStringerProRequest(token: string) {

  return new Promise<any[]>((resolve, reject) => {

    axios.post(`${API_URL}/stringer.pro_request`, {}, {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiStringerDeleteRequest(token: string, stringerId: string | null) {

  return new Promise<any[]>((resolve, reject) => {

    axios.post(`${API_URL}/stringer.delete`, {
      stringer_id: stringerId ? stringerId : undefined
    }, {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiStringersSearch(token: string, cancelToken: any, input: string) {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(`${API_URL}/stringers.search?input=${input}`,{
      cancelToken,
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data.stringers);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export const apiStringerResponse = (
  token: string,
  task_id: number,
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/stringer.response', {
      task_id,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiStringerUpdateComment = (
  token: string,
  id: number,
  comment: string,
  referrer: string
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/stringer.update_comment', {
      id,
      comment,
      referrer
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiStringerInvite = (
  token: string,
  {email, firstName, lastName, phone, location}: {
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    location: Location | null,
  }
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/stringer.invite', {
      email,
      first_name: firstName,
      last_name: lastName,
      phone,
      location_text: location?.location_text,
      location_place_id: location?.location_place_id,
      location_lat: location?.location_lat,
      location_lng: location?.location_lng,
      location_country: location?.location_country,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}




