import React, {useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {userSignOutAction} from "../../reducers/user";
import {useDispatch, useSelector} from "react-redux";

export default function UserInfo({}) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector(state => state.user.user);

  const signOut = () => {
    dispatch(userSignOutAction());
    navigate('/');
  }

  if (user) {
    return (
      <div className="site-header-user">
        <div className="site-header-user-top">
          <img src="/images/user.png" alt="No image" className="site-header-user-top-image"/>
          <span className="site-header-user-top-name">{user.first_name} {user.last_name}</span>
          <img src="/images/arrow.svg" className="site-header-user-top-arrow"/>
        </div>
        <div className="site-header-user-menu">
          <div className="site-header-user-menu-inner">
            {['stringer', 'client'].indexOf(user?.role) !== -1 && <NavLink to={'/profile'} className="site-header-user-menu-element">Edit profile</NavLink>}
            <span onClick={signOut} className="site-header-user-menu-element">Sign Out</span>
          </div>
        </div>
      </div>
    )
  }

  return <div/>

}
