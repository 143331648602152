import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {useOnClickOutside} from "usehooks-ts";

const CancelToken = axios.CancelToken;
let source: any;

function MultiSearch({onChangeSelectedElements, selectedElements, placeholder, onSearch}: any) {

  const [elements, setElements] = useState<any[]>([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  const ref = useRef(null)

  const handleClickOutside = () => {
    setElements([])
    setInput('')
  }

  useOnClickOutside(ref, handleClickOutside)

  const select = (element: any) => {

    if (selectedElements.indexOf(element) === -1) {
      selectedElements.push(element)
    }
    onChangeSelectedElements(selectedElements)
    setElements([])
    setInput('')

  }

  const search = async (text: any) => {

    if (source) {
      source.cancel();
    }

    setInput(text);

    if (text.length > 1) {

      source = CancelToken.source();
      setLoading(true);
      let elements = await onSearch(source.token, text);
      setElements(elements);
      setLoading(false);

    } else {
      setElements([]);
    }

  }

  const deleteElement = (element: any) => {

    if (selectedElements.indexOf(element) !== -1) {
      selectedElements.splice(selectedElements.indexOf(element), 1)
    }
    onChangeSelectedElements(selectedElements)

  }

  return (
    <div className="custom-select-select" ref={ref}>
      <div className="custom-select-top multi row">
        {selectedElements.map((element: any) => {
          return (
            <div className="custom-select-tip">
              <span className="custom-select-tip-label">{element}</span>
              <span className="custom-select-tip-close" onClick={() => {
                deleteElement(element)
              }}>x</span>
            </div>
          )
        })}
        <input
          type="text"
          className={"custom-select-top-input"}
          placeholder={placeholder}
          value={input}
          onChange={(event) => {
            search(event.target.value);
          }}
        />
        {loading && (
          <div className="custom-select-top-spinner">
            <div className="spinner"/>
          </div>
        )}
      </div>
      {
        (elements.length || input.length > 0) > 0 && (
          <div className="custom-select-submenu">
            {
              elements.map(function (element: any) {
                if (selectedElements.indexOf(element) === -1) {
                  return (
                    <span
                      key={element.place_id}
                      className="custom-select-submenu-element"
                      onClick={() => {
                        select(element.name);
                      }}
                    >
                    {element.name}
                  </span>
                  )
                }
              })
            }
            {elements.map(element => element.name).indexOf(input) === -1 && <span className="custom-select-submenu-element" onClick={() => {select(input)}}>{input}</span>}
          </div>
        )
      }
    </div>
  );
}

export default MultiSearch
