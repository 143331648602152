import React from 'react';
import {
  apiVideoCitiesSearch,
  apiVideoCountrySearch,
  apiVideosGet,
  apiVideoStateSearch, apiVideoTagSearch
} from "../../../actions/videoActions";
import {apiPartnersGetReceive, apiPartnersGetSend} from "../../../actions/partnesActions";
import TablePage, {TableConfig, TableFilter, TableHead, TablePreset} from "../../../components/table/TablePage";
import Video from "../../../components/Video";
import {VideoResponse} from "../../../types/responses";
import {apiCategoriesGet} from "../../../actions/categoriesActions";
import {apiUserSearch} from "../../../actions/userActions";

const getUploadedBy = (video: VideoResponse) => {

  if (video.user.role === 'partner') {
    return video.user.first_name
  }
  if (video.user.role === 'admin') {
    return 'Moderator'
  }
  if (video.user.role === 'stringer') {
    return `Stringer\n\n${video.user.email}`
  }
  return "";

}

const getThumbPath = (video: VideoResponse) => {

  const files = video.files.filter((file: any) => file.status === 3);
  if (files.length > 0) {
    return `gallery/videos/thumbs/${files[0].id}/00001.png`;
  }

  return null;

}

const configHeads : TableHead[] = [
  {
    name: '',
    width: '20px',
    key: 'empty',
  },
  {
    name: 'ID',
    width: '70px',
    key: 'id',
    type: 'string'
  },
  {
    name: 'Title',
    width: null,
    key: 'title',
    type: 'link',
    options: {
      href: '/panel/videos/',
    }
  },
  {
    name: 'Files',
    width: null,
    key: 'filesNum',
    type: 'string',
  },
  {
    name: 'Location',
    width: '200px',
    key: 'location_text',
    type: 'string'
  },
  {
    name: 'Filming at',
    width: '100px',
    key: 'filming_date',
    type: 'date',
    options:{
      format: 'DD/MM/YYYY'
    }
  },
  {
    name: 'Uploaded by',
    width: '130px',
    key: 'uploaded_by',
    type: 'string',
  },
  {
    name: 'Status',
    width: '145px',
    key: 'status',
    type: 'status',
    options: {
      circle: {
        0: 'red',
        1: 'orange',
        2: 'green',
        3: 'orange',
      },
      label: {
        0: 'Declined',
        1: 'On moderation',
        2: 'Approved',
        3: 'On Hold',
      }
    }
  },
  {
    name: 'Uploaded at',
    width: '100px',
    key: 'created_at',
    type: 'date',
    options:{
      format: 'DD/MM/YYYY HH:mm:ss'
    }
  },
]

const configFilters : TableFilter[] = [
  {
    id: 'Status',
    type: 'select',
    label: 'Status',
    options: {
      label: 'Please select status',
      elements: ['On moderation', 'Approved', 'Declined', 'On Hold']
    }
  },
  {
    id: 'Partner (Receive)',
    type: 'select',
    label: 'Partner (Receive)',
    options: {
      label: 'Please select partner',
      secured: true,
      onLoadElements: apiPartnersGetReceive
    }
  },
  {
    id: 'Partner (Send)',
    type: 'select',
    label: 'Partner (Send)',
    options: {
      label: 'Please select partner',
      secured: true,
      onLoadElements: apiPartnersGetSend
    }
  },
  {
    id: 'Category',
    type: 'select',
    label: 'Category',
    options: {
      label: 'Please select category',
      secured: true,
      onLoadElements: apiCategoriesGet
    }
  },
  {
    id: 'Tag',
    type: 'search',
    label: 'Tag',
    options: {
      label: 'Please search tag',
      onSearch: apiVideoTagSearch
    }
  },
  {
    id: 'Email',
    type: 'search',
    label: 'Email',
    options: {
      label: 'Please search email',
      onSearch: apiUserSearch,
      secured: true
    }
  },
  {
    id: 'ID',
    type: 'textarea',
    label: 'ID',
  },
  {
    id: 'Country',
    type: 'search',
    label: 'Country',
    options: {
      label: 'Please search country',
      onSearch: apiVideoCountrySearch
    }
  },
  {
    id: 'State',
    type: 'search',
    label: 'State/Region',
    options: {
      label: 'Please search state or region',
      onSearch: apiVideoStateSearch
    }
  },
  {
    id: 'City',
    type: 'search',
    label: 'City',
    options: {
      label: 'Please search city',
      onSearch: apiVideoCitiesSearch
    }
  },
  {
    id: 'Filming At',
    type: 'date',
    label: 'Filming At',
  },
  {
    id: 'Uploaded At',
    type: 'date',
    label: 'Uploaded At',
  },
  {
    id: 'Approved At',
    type: 'date',
    label: 'Approved At',
  },
  {
    id: 'Source',
    type: 'select',
    label: 'Source',
    options: {
      label: 'Please select source',
      elements: ['IDF', 'DVIDS USA', 'Quds News Network', 'PRCS', 'Palestine Action', '@Mehrnews', '@PSCupdates']
    }
  },
  {
    id: 'Referrer',
    type: 'select',
    label: 'Referrer',
    options: {
      label: 'Please select referrer',
      elements: ['Da', 'P', 'L', 'K', 'M', 'D', 'Yu', 'Y', 'Sc']
    }
  },
  {
    id: 'Other',
    type: 'select',
    label: 'Other',
    options: {
      label: 'Please select filter',
      elements: ['Only stringers', 'Only moderators']
    }
  },
]

const configPresets : TablePreset[] = [
  {
    label: 'On moderation',
    filters: [
      {
        id: 'Status',
        label: 'Status',
        value: 'On moderation'
      },
    ]
  },
  {
    label: 'On moderation (Only Stringers)',
    filters: [
      {
        id: 'Status',
        label: 'Status',
        value: 'On moderation'
      },
      {
        id: 'Other',
        label: 'Other',
        value: 'Only stringers'
      },
    ]
  },
  {
    label: 'On moderation (Spectee)',
    filters: [
      {
        id: 'Status',
        label: 'Status',
        value: 'On moderation'
      },
      {
        id: 'Partner (Receive)',
        label: 'Partner (Receive)',
        value: 'Spectee'
      },
    ]
  },
  {
    label: 'Hidden',
    filters: [
      {
        id: 'Other',
        label: 'Other',
        value: 'Only hidden'
      },
    ]
  },
  {
    label: 'Declined',
    filters: [
      {
        id: 'Status',
        label: 'Status',
        value: 'Declined'
      },
    ]
  },
  {
    label: 'Only moderators',
    filters: [
      {
        id: 'Other',
        label: 'Other',
        value: 'Only moderators'
      },
    ]
  },
  {
    label: 'Referrer P/L/K/M/D/Da',
    filters: ['P', 'L', 'K', 'M', 'D', 'Da'].map(el => {
      return {
        id: 'Referrer',
        label: 'Referrer',
        value: el
      }
    })
  }
]

const config: TableConfig = {
  title: 'Videos',
  key: 'videos',
  functions: {
    loadElementsMethod: apiVideosGet,
    convertElementsMethod: (elements: VideoResponse[]) => [
      ...elements.map(video => {
        return  {
          ...video,
          filesNum: video.files.length,
          uploaded_by: getUploadedBy(video),
          thumbPath: getThumbPath(video)
        }
      })
    ],
  },
  options: {
    hasSwitcher: true,
    hasSearch: true,
    createButton: {
      label: 'Upload video',
      link: '/video/upload'
    },
    exportButton: {
      link: "sdfjk3409ewfdsf/videos.export"
    },
    blockView: {
      obj: (obj: any) => {
        return (
          <Video
            video={obj}
            fields={['location_text', 'filming_date', 'status']}
            link={`/panel/videos/${obj.id}`}
          />
        )
      },
      className: 'videos-block-view row',
    }
  },
  heads: configHeads,
  filters: configFilters,
  presets: configPresets,
}

function PanelVideos() {

  return (
    <TablePage
      config={config}
    />
  );

}

export default PanelVideos;
