import React from 'react';
import ConfirmModal, {BaseModalProps} from "./ConfirmModal";
import {useSelector} from "react-redux";
import {apiTaskDeclineExecutor} from "../../actions/taskActions";

function DeclineExecutorModal({onClose, open, taskId,}: BaseModalProps & {
  taskId: number | null
}) {

  const token = useSelector(state => state.user.token)

  return (
    <>
      <ConfirmModal
        open={open && taskId !== null }
        onClose={onClose}
        onUpdate={() => {
          onClose()
          window.location.reload();
        }}
        title='Decline executor?'
        message={`Are you sure you want to delete this stringer from executor?`}
        submitButtonText='Decline'
        cancelButtonText='Cancel'
        callback={async () => {
          if (taskId !== null) {
            await apiTaskDeclineExecutor(token, taskId)
          }
        }}
      />
    </>
  )

}

export default DeclineExecutorModal
