import {CustomField} from "./index";

export const taskFields : {
  [name: string] : CustomField
} = {
  title: {
    id: 'taskTitle',
    label: 'Title',
    type: 'input',
  },
  location: {
    id: 'taskLocation',
    label: 'Location',
    type: 'location'
  },
  deadline: {
    id: 'taskDeadline',
    label: 'Deadline',
    type: 'datetime'
  },
  budget: {
    id: 'taskBudget',
    label: 'Budget',
    type: 'input'
  },
  stringerBudget: {
    id: 'taskStringerBudget',
    label: 'Stringer budget',
    type: 'input'
  },
  description: {
    id: 'taskDescription',
    label: 'Description',
    type: 'textarea',
    options: {
      minRows: 4
    }
  },
  contactPhone: {
    id: 'taskContactPhone',
    label: 'Contact Phone',
    type: 'input'
  },
  contactName: {
    id: 'taskContactName',
    label: 'Contact Name',
    type: 'input'
  }
}
