import React, { useState } from 'react';
import Modal from "../ui/Modal";
import Button from "../ui/Button";
import {BaseModalProps} from "./ConfirmModal";
import Field from "../ui/Field";
import {useSelector} from "react-redux";
import {apiVideoMoveFile} from "../../actions/videoActions";

function MoveVideoModal({
  onClose, onUpdate, open, fileId
}: BaseModalProps & {fileId: any}) {

  const token = useSelector(state => state.user.token)

  const [loading, setLoading] = useState(false)
  const [videoId, setVideoId] = useState<string | null>(null)
  const [error, setError] = useState<undefined | string>(undefined)

  const handleConfirm = async () => {

    setLoading(true)

    const response = await apiVideoMoveFile(token, videoId, fileId)
    if (!response.status) {
      alert(response.error)
      setLoading(false)
      return;
    }
    setLoading(false)
    onUpdate && onUpdate()

  }

   return (
     <Modal
       open={open}
       onClose={onClose}
       content={(
         <div className="modal-window confirm-window">
           <div className="modal-window-top">
             <span className="modal-window-top-title">{'Move video file'}</span>
             <div className="modal-window-top-close" onClick={onClose}/>
           </div>
           <div className="modal-window-content confirm-window-content">
             <Field
               id="changeAuthorStringer"
               label="Video ID"
               type="input"
               value={videoId}
               onChange={(value) => {
                 setVideoId(value)
                 setError(undefined)
               }}
               error={error}
             />
           </div>
           <div className="modal-window-bottom">
             <div className="modal-window-bottom-buttons">
               <button className="modal-window-bottom-cancel-button" onClick={onClose}>Cancel</button>
               <Button
                 className={'modal-window-bottom-submit-button'}
                 label={'Submit'}
                 loading={loading}
                 onClick={handleConfirm}
                 type={'button'}
               />
             </div>
           </div>
         </div>
       )}
     />
 )
}

export default MoveVideoModal
