import React from 'react';
import Field from "../../../../../components/ui/Field";
import {apiClientsSearch} from "../../../../../actions/clientActions";

function ClientSearch({selectedClient, onChange}: any) {

  return (
    <div>
      <Field
        className={"create-page-block-element"}
        id="videoTags"
        label="Client"
        type="singleSearch"
        value={null}
        onChange={(client) => {
          onChange(client)
        }}
        options={{
          placeholder: 'Start type client',
          onSearch: apiClientsSearch,
          selectCallback: (element: any) => {
            return `${element.organization } (${element.user && `${element.user.first_name} ${element.user.last_name}`})`
          },
          secured: true,
        }}
      />
      {selectedClient !== null && (
        <div className="user-inner">
          <img src="/images/avatar.svg" alt="" className="user-inner-image"/>
          <div className="user-inner-block-1">
            <span className="user-inner-username">{selectedClient.user && `${selectedClient.user.first_name} ${selectedClient.user.first_name}`}</span>
            <span className="user-inner-date">Active in the last 11.03.2021</span>
            <span className="user-inner-position">{selectedClient.organization}</span>
          </div>
          <div className="user-inner-block-2">
            <span className="user-inner-element"><b>e-mail:</b> {selectedClient.user.email}</span>
            <span className="user-inner-element"><b>phone:</b> {selectedClient.contact_phone}</span>
            <span className="user-inner-element"><b>position:</b> {selectedClient.position}</span>
          </div>
        </div>
      )}
    </div>
  );

}

export default ClientSearch
