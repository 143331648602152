import React, {useState} from 'react';
import Field from "../../components/ui/Field";
import {formCheckErrors, formGenerateErrorsObject} from "../../core/form";
import {apiUserRegister} from "../../actions/userActions";
import {useNavigate} from "react-router-dom";
import Modal from "../../components/ui/Modal";
import Activation from "../components/Activation";

const errorsList = {
  email: 'email',
  password: 'password',
  repeatPassword: 'repeat'
}

const initialValues = {
  email: '',
  password: '',
  repeatPassword: '',
}

function Register({onClose, open}: any) {

  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [loading, setLoading] = useState(false)
  const [activationEmail, setActivationEmail] = useState<null | string>(null);

  const changeField = (field: string, value: any) => {
    setValues({...values, [field]: value})
    setErrors({...errors, [field]: ""})
  }

  const Submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);
    console.log(errorResponse);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setLoading(true);

    try {

      const apiResponse = await apiUserRegister(values.email, values.password);
      if (apiResponse.status) {
        setActivationEmail(values.email);
        setLoading(false);
      } else {
        setErrors({...errors, email: apiResponse.error});
        setLoading(false);
      }

    } catch (error) {
      console.log(error);
    }

  }

  if (activationEmail !== null) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        className="auth-window"
        content={(
          <Activation
            email={activationEmail}
            onSignOut={() => {
              setActivationEmail(null);
            }}
          />
        )}
      />
    )
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={(
        <div className="auth-window register">
          <span className="auth-window-close" onClick={onClose}/>
          <span className="auth-window-title">Sign Up</span>
          <span className="auth-window-sub-title">Fill all necessary fields</span>
          <div className="auth-window-elements">
            <Field
              className="auth-window-element"
              id="registerEmail"
              label="Email *"
              type="input"
              options={{
                type: 'email'
              }}
              error={errors.email}
              value={values.email}
              onChange={(value) => changeField("email", value)}
            />
            <Field
              className="auth-window-element"
              id="registerPassword"
              label="Password *"
              type="input"
              options={{
                type: 'password'
              }}
              error={errors.password}
              value={values.password}
              onChange={(value) => changeField("password", value)}
            />
            <Field
              className="auth-window-element"
              id="registerRepeatPassword"
              label="Repeat password *"
              type="input"
              options={{
                type: 'password'
              }}
              error={errors.repeatPassword}
              value={values.repeatPassword}
              onChange={(value) => changeField("repeatPassword", value)}
            />
            <button className={`custom-button auth-window-button ${loading ? "loading" : ""}`} onClick={Submit}>
              <span className="custom-button-label">Sign Up</span>
              {loading && <span className="custom-button-spinner"><span className="spinner"/></span>}
            </button>
            {
              /*
               <div className="auth-window-line">
                <span className="auth-window-line-text">or</span>
              </div>
              <div className="auth-window-social-icons">
                <FacebookAuth/>
                <span className="auth-window-social-icon google"/>
                <span className="auth-window-social-icon linkedin"/>
              </div>
              <span className="auth-window-social-text">or sign up with social networks</span>
               */
            }
          </div>
        </div>
      )}
    />
  );
}

export default Register;
