import React from 'react';
import ConfirmModal, {BaseModalProps} from "./ConfirmModal";
import {useSelector} from "react-redux";
import {apiTaskSelectExecutor} from "../../actions/taskActions";

function SelectExecutorModal({onClose, open, taskId, stringerId}: BaseModalProps & {
  taskId: number | null
  stringerId: number | null
}) {

  const token = useSelector(state => state.user.token)

  return (
    <>
      <ConfirmModal
        open={open && taskId !== null && stringerId !== null}
        onClose={onClose}
        onUpdate={() => {
          onClose()
          window.location.reload();
        }}
        title='Select executor?'
        message={`Are you sure you want to select this stringer as the executor?`}
        submitButtonText='Select'
        cancelButtonText='Cancel'
        callback={async () => {
          if (taskId !== null && stringerId !== null) {
            await apiTaskSelectExecutor(token, taskId, stringerId)
          }
        }}
      />
    </>
  )

}

export default SelectExecutorModal
