import React, {useEffect, useState} from 'react';
import Modal from "../../../ui/Modal";
import {formCheckErrors, formGenerateErrorsObject} from "../../../../core/form";
import {renderField} from "../../../../fields";
import {portfolioFields} from "../../../../fields/portfolio";
import Button from "../../../ui/Button";
import {useSelector} from "react-redux";
import {apiPortfolioCreate} from "../../../../actions/portfolioActions";

const errorsList: any = {
  title: 'required',
  description: 'required',
  client: 'required',
}

const initialValues: any = {
  title: '',
  description: '',
  client: '',
  filmingDate: null,
  links: []
}

function StringerModalPortfolioCreate({open, stringer, onClose, onUpdate} : any) {

  const token = useSelector(state => state.user.token)

  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [loading, setLoading] = useState(false)

  const getFieldActions = (key: string) => {
    return {
      value: values[key],
      error: errors[key],
      onChange: (value: any) => {
        setValues({
          ...values,
          [key]: value,
        })
        setErrors({
          ...errors,
          [key]: '',
        })
      }
    }
  }

  const submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setLoading(true)
    await apiPortfolioCreate(token, values)
    setValues(initialValues)
    onUpdate()

  }



  return (
    <Modal
      open={open}
      onClose={onClose}
      content={(
        <div className="modal-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Create portfolio</span>
            <div className="modal-window-top-close" onClick={() => onClose()}/>
          </div>
          <div className="modal-window-content">
            <div className="modal-window-content-inner">
              <div className="modal-window-row">
                {renderField({
                  ...portfolioFields.title,
                  ...getFieldActions('title'),
                  required: true,
                })}
              </div>
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  {renderField({
                    ...portfolioFields.filmingDate,
                    ...getFieldActions('filmingDate'),
                    required: true,
                  })}
                </div>
                <div className="modal-window-col">
                  {renderField({
                    ...portfolioFields.client,
                    ...getFieldActions('client'),
                  })}
                </div>
              </div>
              <div className="modal-window-row">
                {renderField({
                  ...portfolioFields.links,
                  ...getFieldActions('links'),
                })}
              </div>
              <div className="modal-window-row">
                {renderField({
                  ...portfolioFields.description,
                  ...getFieldActions('description'),
                  required: true,
                })}
              </div>
            </div>
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <button className="modal-window-bottom-cancel-button" onClick={onClose}>Cancel</button>
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Submit'}
                loading={loading}
                onClick={submit}
                type={'button'}
              />
            </div>
          </div>
        </div>
      )}
    />
  )

}

export default StringerModalPortfolioCreate
