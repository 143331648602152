import React, {useState} from 'react';
import Field from "../../components/ui/Field";
import {formCheckErrors, formGenerateErrorsObject} from "../../core/form";
import {apiUserLogin} from "../../actions/userActions";
import {useDispatch} from "react-redux";
import {userSignInAction} from "../../reducers/user";
import {useNavigate} from "react-router-dom";
import Modal from "../../components/ui/Modal";
import Activation from "../components/Activation";

const errorsList = {
  email: 'email',
  password: 'password'
}

const initialValues = {
  email: '',
  password: ''
}

function ModalLogin({onClose, open, onOpenForgotPasswordModal}: any) {

  const dispatch = useDispatch();

  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [loading, setLoading] = useState(false)
  const [activationEmail, setActivationEmail] = useState<null | string>(null);
  const navigate = useNavigate();

  const changeField = (field: string, value: any) => {
    setValues({...values, [field]: value})
    setErrors({...errors, [field]: ""})
  }

  const Submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setLoading(true);

    try {

      const apiResponse = await apiUserLogin(values.email, values.password);
      if (apiResponse.status) {
        if (apiResponse.user.status === 1) {
          dispatch(userSignInAction({
            token: apiResponse.token,
            user: apiResponse.user
          }));
          onClose()
          if (apiResponse.user.role === 'admin') {
            navigate('/panel/videos')
            return
          }
          if (apiResponse.user.role === 'stringer') {
            navigate('/profile')
            return
          }
          navigate('/gallery')
        } else {
          if (apiResponse.user.status === 0) {
            setActivationEmail(values.email);
            setLoading(false);
          }
        }
      } else {
        setErrors({...errors, email: apiResponse.error});
        setLoading(false);
      }

    } catch (error) {
      console.log(error);
    }

  }

  if (activationEmail !== null) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        className="auth-window"
        content={(
          <Activation
            email={activationEmail}
            onSignOut={() => {
              setActivationEmail(null);
            }}
          />
        )}
      />
    )
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={(
        <div className="auth-window">
          <span className="auth-window-close" onClick={onClose}/>
          <span className="auth-window-title">Sign In</span>
          <span className="auth-window-sub-title">Enter email and password</span>
          <div className="auth-window-elements">
            <Field
              className="auth-window-element"
              id="loginEmail"
              label="Email *"
              type="input"
              options={{
                type: 'email'
              }}
              error={errors.email}
              value={values.email}
              onChange={(value) => changeField("email", value)}
            />
            <Field
              className="auth-window-element"
              id="loginPassword"
              error={errors.password}
              label="Password *"
              type="input"
              options={{
                type: 'password'
              }}
              value={values.password}
              onChange={(value) => changeField('password', value)}
            />
            <div className="auth-window-forgot-password">
              <span className="auth-window-forgot-password-link" onClick={() => {onOpenForgotPasswordModal()}}>Forgot Password?</span>
            </div>
            <button className={`custom-button auth-window-button ${loading ? "loading" : ""}`} onClick={Submit}>
              <span className="custom-button-label">Sign In</span>
              {loading && <span className="custom-button-spinner"><span className="spinner"/></span>}
            </button>
            {
              /*
              <div className="auth-window-line">
               <span className="auth-window-line-text">or</span>
              </div>
              <div className="auth-window-social-icons">
                <FacebookAuth/>
                <span className="auth-window-social-icon google"/>
                <span className="auth-window-social-icon linkedin"/>
              </div>
              <span className="auth-window-social-text">or sign in with social networks</span>
               */
            }
          </div>
        </div>
      )}
    />
  );
}

export default ModalLogin;
