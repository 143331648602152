import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useSelector} from "react-redux";
import {apiVideosGet} from "../../../actions/videoActions";
import ReactPaginate from "react-paginate";
import Loading from "../../ui/Loading";
import Video from "../../Video";

const CancelToken = axios.CancelToken;
let source: any;

const getLink = (video: any, editable: boolean) => {
  if (editable) {
    if (video.status === 2) {
     return `/gallery/${video.hash}`
    } else {
      return `/video/edit/${video.id}`
    }
  } else {
    return `/panel/videos/${video.id}`;
  }

}

function StringerVideos({stringer, editable} : any) {

  const token = useSelector(state => state.user.token)

  const [elements, setElements] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadVideos();
  }, [page])

  const loadVideos = async () => {

    if (source) {
      source.cancel();
    }

    source = CancelToken.source();
    setLoading(true)

    const response: any = await apiVideosGet({
      cancelToken: source.token,
      token,
      sortColumn: '',
      sortDir: '',
      search: '',
      filters: '',
      offset: page * 16,
      limit: 16,
      user_id: !editable ? stringer.user.id : '',
      }
    );

    setElements(response.videos)
    setCount(response.count)
    setLoading(false)

  }

  const pagination = (
    <div className="table-page-paginations" style={{marginTop: 20}}>
      <ReactPaginate
        forcePage={page}
        breakLabel="..."
        nextLabel=">"
        onPageChange={(event) => {
          setPage(event.selected);
        }}
        pageRangeDisplayed={5}
        pageCount={Math.ceil(count/15)}
        previousLabel="<"
      />
    </div>
  )

  if (loading) {
    return <Loading/>
  }

  return (
    <>
      <div className="stringer-page-videos">
        <div className="video-page-top">
          <div className="video-page-top-right">
            {count > 15 && pagination}
          </div>
        </div>
        <div className="stringer-page-videos-elements row">
          {elements.map((video: any) => {
            return (
              <Video
                video={video}
                fields={['location_text', 'filming_date', 'status', 'created_at']}
                link={getLink(video, editable)}
              />
            )
          })}
        </div>
      </div>
    </>
  );
}

export default StringerVideos;
