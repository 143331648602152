import React, {useState} from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import {getDateFromString} from "../../../../../core/helpers";
import {formGenerateErrorsObject} from "../../../../../core/form";
import Button from "../../../../../components/ui/Button";
import {renderField} from "../../../../../fields";
import {taskFields} from "../../../../../fields/task";
import TaskTypes from "../../components/TaskTypes";
import {TaskLinks} from "../../components/TaskLinks";
import {TaskMembers} from "../../components/TaskMembers";
import {apiTaskUpdate, apiTaskUpdateStatus} from "../../../../../actions/taskActions";
import {TaskExecutor} from "../../components/TaskExecutor";
import {StringerType} from "../../../../../types/models";
import {TaskComments} from "../../components/TaskComments";
import {TaskClient} from "../../components/TaskClient";

const errorsList = {
  title: 'required',
  description: 'required',
}

function TaskPageGeneralInfo({task, executor, onChangeTaskStatus}: {
  task: any,
  executor: StringerType | null
  onChangeTaskStatus: (status: number) => void
}) {

  const token = useSelector(state => state.user.token)

  const [values, setValues] = useState<any>({
    id: task.id,
    title: task.title,
    description: task.description,
    location: {
      location_text: task.location_text,
      location_place_id: task.location_place_id,
    },
    contact_name: task.contact_name,
    contact_phone: task.contact_phone,
    budget: task.budget,
    stringer_budget: task.stringer_budget,
    deadline: getDateFromString(task.deadline),
    links: task.links.map((link: any) => {
      return link.link
    }),
    types: task.types.map((type: any) => {
      return type.name
    }),
    members: task.members,
    status: task.status,
    material_filmed: !!task.material_filmed,
    material_accepted: !!task.material_accepted,
    customer_sent_payment: !!task.customer_sent_payment,
    stringer_received_payment: !!task.stringer_received_payment
  })
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))

  const [submitLoading, setSubmitLoading] = useState(false)
  const [approveLoading, setApproveLoading] = useState(false)
  const [declineLoading, setDeclineLoading] = useState(false)

  const changeField = (field: string, value: any) => {
    setValues({...values, [field]: value})
    setErrors({...errors, [field]: ""})
  }

  const getFieldActions = (key: string) => {
    return {
      value: values[key],
      error: errors[key],
      onChange: (value: any) => {
        setValues({
          ...values,
          [key]: value,
        })
        setErrors({
          ...errors,
          [key]: '',
        })
      }
    }
  }

  const link = (_link: string) => {
    return (
      <div className="task-page-links-element">
        <a className="task-page-links-element-link" href={_link} target={"_blank"}>{_link}</a>
        <img onClick={() => {
          navigator?.clipboard?.writeText(_link);
        }} src="/images/copy-icon.svg" alt="" className="task-page-links-element-icon"/>
      </div>
    )
  }

  const customCheckbox = (label: string, key: string) => {
    return (
      <label className="task-page-aside-checkbox custom-checkbox">
        <input
          type="checkbox"
          checked={values[key]}
          onChange={(event) => {
            setValues({
              ...values,
              [key]: event.target.checked
            })
          }}
        />
        <div className="custom-checkbox-checkmark">
          <div className="custom-checkbox-checkmark-inner"/>
        </div>
        <span className="custom-checkbox-label">{label}</span>
      </label>
    )
  }

  const submit = async () => {

    setSubmitLoading(true)

    await apiTaskUpdate(token, {
      id: task.id,
      ...values
    })

    setSubmitLoading(false)

  }

  const approve = async () => {
    setApproveLoading(true)
    await apiTaskUpdateStatus(token, task.id, 2);
    onChangeTaskStatus(2)
    setApproveLoading(false)
  }

  const decline = async () => {
    setDeclineLoading(true)
    await apiTaskUpdateStatus(token, task.id, 0);
    onChangeTaskStatus(0)
    setDeclineLoading(false)
  }

  return (
    <>
      <div className="task-page-aside">
        {values.budget ? (
          <div className="task-page-budget">
            <span className="task-page-budget-value">{`$${values.budget}`}</span>
            <span className="task-page-budget-label">Agency budget</span>
          </div>
        ): <div/>}
        {values.stringer_budget ? (
          <div className="task-page-budget">
            <span className="task-page-budget-value">{`$${values.stringer_budget}`}</span>
            <span className="task-page-budget-label">Stringer’s budget</span>
          </div>
        ) : <div/>}
        <div className="task-page-aside-elements">
          <span className="task-page-aside-element">Deadline: <b>{values.deadline !== null && moment(values.deadline).format('DD/MM/YYYY')}</b></span>
          <span className="task-page-aside-element">Customer: <b>{task.client !== null && task.client.client?.organization}</b></span>
        </div>
        {values.links.length > 0 && (
          <div className="task-page-links">
            <span className="task-page-links-label">Links</span>
            {values.links.map((_link: any) => link(_link))}
          </div>
        )}
        <div className="task-page-aside-checkboxes">
          {customCheckbox('Material filmed', 'material_filmed')}
          {customCheckbox('Material accepted', 'material_accepted')}
          {customCheckbox('Client payed', 'customer_sent_payment')}
          {customCheckbox('Stringer received payment', 'stringer_received_payment')}
        </div>
      </div>
      <div className="task-page-right">
        <div className="task-page-right-content">
          <div className="task-page-right-content-col">
            <div className="task-page-block">
              <div className="task-page-block-top">
                <span className="task-page-block-title">General Information</span>
              </div>
              <div className="task-page-fields">
                {renderField({
                  ...taskFields.title,
                  ...getFieldActions('title'),
                  className: "task-page-field",
                  required: true,
                })}
                {renderField({
                  ...taskFields.location,
                  ...getFieldActions('location'),
                  className: "task-page-field",
                  required: true,
                })}
                {renderField({
                  ...taskFields.deadline,
                  ...getFieldActions('deadline'),
                  className: "task-page-field",
                  required: true,
                })}
                {renderField({
                  ...taskFields.description,
                  ...getFieldActions('description'),
                  className: "task-page-field",
                  required: true,
                })}
              </div>
            </div>
            <div className="task-page-block">
              <div className="task-page-block-top">
                <span className="task-page-block-title">Budget</span>
              </div>
              <div className="task-page-fields">
                <div className="task-page-fields-row">
                  {renderField({
                    ...taskFields.budget,
                    ...getFieldActions('budget'),
                    className: "task-page-field",
                  })}
                  {renderField({
                    ...taskFields.stringerBudget,
                    ...getFieldActions('stringer_budget'),
                    className: "task-page-field",
                  })}
                </div>
              </div>
            </div>
            <div className="task-page-block">
              <div className="task-page-block-top">
                <span className="task-page-block-title">Contact Information</span>
              </div>
              <div className="task-page-fields">
                <div className="task-page-fields-row">
                  {renderField({
                    ...taskFields.contactPhone,
                    ...getFieldActions('contact_phone'),
                    className: "task-page-field",
                    required: true,
                  })}
                  {renderField({
                    ...taskFields.contactName,
                    ...getFieldActions('contact_name'),
                    className: "task-page-field",
                    required: true,
                  })}
                </div>
              </div>
            </div>
            <div className="task-page-block">
              <div className="task-page-block-top">
                <span className="task-page-block-title">Links</span>
              </div>
              <div className="task-page-fields">
                <TaskLinks
                  links={values.links}
                  onChange={(_links: any) => {
                    changeField('links', [..._links])
                  }}
                />
              </div>
            </div>
            <div className="task-page-block">
              <div className="task-page-block-top">
                <span className="task-page-block-title">Types of Work</span>
              </div>
              <TaskTypes
                selectedTypes={values.types}
                onChange={(_types) => {
                  changeField('types', _types)
                }}
              />
            </div>
          </div>
          <div className="task-page-right-content-col">
            {executor !== null && (
              <div className="task-page-block">
                <div className="task-page-block-top">
                  <span className="task-page-block-title">Executor</span>
                </div>
                <TaskExecutor stringer={executor} taskId={task.id}/>
              </div>
            )}
            {task.user !== null && task.user.client !== null && (
              <div className="task-page-block">
                <div className="task-page-block-top">
                  <span className="task-page-block-title">Client</span>
                </div>
                <TaskClient client={task.user.client} user={task.user}/>
              </div>
            )}
            <div className="task-page-block">
              <div className="task-page-block-top">
                <span className="task-page-block-title">Members</span>
              </div>
              <TaskMembers members={task.members}/>
            </div>
            <TaskComments taskId={task.id}/>
          </div>
        </div>
        <div className="task-page-bottom">
          {task.status !== 0 && !approveLoading && (
            <Button
              className={'gray'}
              label={'Decline task'}
              loading={declineLoading}
              onClick={() => {
                decline()
              }}
              type={'button'}
            />
          )}
          <div className="bottom-area-right">
            {task.status !== 2 && !declineLoading && (
              <Button
                className={'blue bottom-area-submit-button'}
                label={'Approve'}
                loading={approveLoading}
                onClick={() => {
                  approve()
                }}
                type={'button'}
              />
            )}
            <Button
              className={'blue bottom-area-submit-button'}
              label={'Save'}
              loading={submitLoading}
              onClick={() => {
                submit()
              }}
              type={'button'}
            />
          </div>
        </div>
      </div>
    </>
  )

}

export default TaskPageGeneralInfo;
