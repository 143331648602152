import React, {useState} from 'react';
import CompleteStringerModal from "./components/CompleteStringerModal";
import useModal from "../../../hooks/useModal";
import CompleteMediaModal from "./components/CompleteMediaModal";

function ProfileComplete() {

  const [stringerModalOpen, setStringerModalOpen] = useState(false)
  const [mediaModalOpen, openMediaModal, closeMediaModal] = useModal()

  return (
    <div>
      <CompleteStringerModal
        open={stringerModalOpen}
        onClose={() => {
          setStringerModalOpen(false)
        }}
      />
      <CompleteMediaModal
        open={mediaModalOpen}
        onClose={closeMediaModal}
      />
      <span className="site-page-title">Select role</span>
      <span className="site-page-sub-title">Choose your role</span>
      <div className="select-roles row">
        <div className="select-role">
          <div className="select-role-icon">
            <i className="fa fa-video-camera"/>
          </div>
          <span className="select-role-title">Stringer</span>
          <button
            className="select-role-button"
            onClick={() => {
              setStringerModalOpen(true)
            }}
          >I am Stringer
          </button>
        </div>
        <div className="select-role">
          <div className="select-role-icon">
            <i className="fa fa-search"/>
          </div>
          <span className="select-role-title">Media</span>
          <button className="select-role-button" onClick={openMediaModal}>I am Media</button>
        </div>
      </div>
    </div>
  )

}

export default ProfileComplete
