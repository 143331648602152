import React from 'react';
import ConfirmModal, {BaseModalProps} from "./ConfirmModal";
import {apiStringerDeleteRequest} from "../../actions/stringerActions";
import {useDispatch, useSelector} from "react-redux";
import {userSignOutAction} from "../../reducers/user";
import {useNavigate} from "react-router-dom";
import useModal from "../../hooks/useModal";

function DeleteProfileModal({onClose, open, editable, stringer_id}: BaseModalProps & {editable: boolean, stringer_id?: string}) {

  const [successModalOpen, openSuccessModal, closeSuccessModal] = useModal();

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector(state => state.user.token)

  return (
    <>
      <ConfirmModal
        open={successModalOpen}
        onClose={closeSuccessModal}
        title='Success'
        message={editable ? 'Your account has been successfully deleted' : 'Account has been successfully deleted'}
        submitButtonText='OK'
        onUpdate={() => {
          if (editable) {
            dispatch(userSignOutAction());
            navigate('/');
          } else {
            window.location.reload()
          }
        }}

      />
      <ConfirmModal
        open={open}
        onClose={onClose}
        onUpdate={() => {
          onClose()
          openSuccessModal()
        }}
        title='Delete account?'
        message={editable ? 'Are you sure you want to delete your profile?' : 'Are you sure you want to delete this profile?'}
        submitButtonText='Delete'
        cancelButtonText='Cancel'
        callback={async () => {
          const stringerId = (!editable && stringer_id) ? stringer_id : null
          await apiStringerDeleteRequest(token, stringerId)
        }}
      />
    </>
  )

}

export default DeleteProfileModal
