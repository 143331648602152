import React from 'react';
import TablePage, {TableConfig, TableFilter, TableHead} from "../../../components/table/TablePage";
import {apiUserSearch} from "../../../actions/userActions";
import {apiLogsGet} from '../../../actions/logActions';
import {useSelector} from "react-redux";
import PageNotFound from "../../pagenotfound/PageNotFound";

const configHeads : TableHead[] = [
  {
    name: '',
    width: '20px',
    key: 'empty',
  },
  {
    name: 'DateTime',
    width: null,
    key: 'created_at',
    type: 'date',
    options:{
      format: 'DD/MM/YYYY HH:mm:ss'
    }
  },
  {
    name: 'Email',
    width: null,
    key: 'email',
    type: 'string'
  },
  {
    name: 'Action',
    width: null,
    key: 'action',
    type: 'string'
  },
  {
    name: 'Video',
    width: null,
    key: 'video',
    type: 'link',
    options: {
      href: '/panel/videos/',
    }
  },
]

const configFilters : TableFilter[] = [
  {
    id: 'DateTime',
    type: 'date',
    label: 'DateTime',
  },
  {
    id: 'Action',
    type: 'select',
    label: 'Action',
    options: {
      label: 'Please select action',
      elements: ['accept', 'decline', 'hold', 'send']
    }
  },
  {
    id: 'Email',
    type: 'search',
    label: 'Email',
    options: {
      label: 'Please search email',
      onSearch: apiUserSearch,
      secured: true
    }
  },
  {
    id: 'Video Id',
    type: 'input',
    label: 'Video Id',
  },
]

const config: TableConfig = {
  title: 'Logs',
  key: 'logs',
  functions: {
    loadElementsMethod: apiLogsGet,
    convertElementsMethod: (elements: any[]) => [
      ...elements.map(element => {
        return  {
          ...element,
          id: element.video ? element.video.id : null,
          email: element.user ? element.user.email : null,
          video: element.video ? element.video.title : null
        }
      })
    ],
  },
  options: {
    hasSwitcher: false,
    hasSearch: false,
  },
  heads: configHeads,
  filters: configFilters,
}

function PanelLogs() {

  const user = useSelector(state => state.user);

  if (user.user && parseInt(user.user.admin) === 0) {
    return <PageNotFound/>
  }

  return (
    <TablePage
      config={config}
    />
  );

}

export default PanelLogs;
