import React from 'react';

function TableSwitcher({onChange, value}: any) {

  return (
    <div className="table-page-switcher">
      <span className={`table-page-switcher-element ${value === 'list' && 'active'}`} onClick={() => {
        onChange('list')
      }}>
        <i className="fa fa-list"/>
      </span>
      <span className={`table-page-switcher-element ${value === 'block' && 'active'}`} onClick={() => {
        onChange('block')
      }}>
        <i className="fa fa-th"/>
      </span>
    </div>
  )

}

export default TableSwitcher;
