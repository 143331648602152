import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Modal from "../../../../../components/ui/Modal";
import Button from "../../../../../components/ui/Button";
import {formCheckErrors, formGenerateErrorsObject} from "../../../../../core/form";
import Field from "../../../../../components/ui/Field";
import {
  apiTransactionCreate,
  apiTransactionDelete,
  apiTransactionsGetFromVideoId
} from "../../../../../actions/transactionActions";
import moment from "moment";

function Sellings({videoId} : {
  videoId: string,
}) {

  const token = useSelector(state => state.user.token)

  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [sellings, setSellings] = useState<any>([])

  useEffect(() => {
    loadSellings()
  }, [])

  const loadSellings = async () => {

    const sellings = await apiTransactionsGetFromVideoId(token, videoId)
    setSellings(sellings)

  }

  const deleteSelling = async (id: any, index: number) => {

    if (window.confirm('Delete selling?')) {
      await apiTransactionDelete(token, id);
      loadSellings()
    }

  }

  return (
    <div className="video-page-upload-block">
      <CreateSellingModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false)
        }}
        onUpdate={() => {
          setCreateModalOpen(false)
          loadSellings()
        }}
        videoId={videoId}
      />
      <span className="video-page-block-title">Sellings</span>
      <div className="video-page-sellings">
        <i className="fa fa-plus-circle video-page-selling-add-icon" onClick={() => {
          setCreateModalOpen(true)
        }}/>
        {sellings.map((selling: any, index: number) => {
          return (
            <div className="video-page-selling">
              <span className="video-page-selling-date">{moment(selling.created_at).format('DD/MM/YYYY')}</span>
              <span className="video-page-selling-price">{selling.value} $</span>
              <i className="fa fa-trash video-page-selling-delete" onClick={() => {
                deleteSelling(selling.id, index)
              }}/>
            </div>
          )
        })}
      </div>
    </div>
  );
}

const errorsList = {
  amount: 'required',
}

const initialValues = {
  amount: 0,
}

function CreateSellingModal({open, onClose, onUpdate, videoId}: any) {

  const token = useSelector(state => state.user.token)

  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [submitLoading, setSubmitloading] = useState(false)

  const changeField = (field: string, value: any) => {
    setValues({...values, [field]: value})
    setErrors({...errors, [field]: ""})
  }

  const submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setSubmitloading(true)
    await apiTransactionCreate(token, videoId, parseInt(values.amount.toString()));

    onUpdate();

  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={(
        <div className="modal-window small">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Add selling</span>
            <div className="modal-window-top-close" onClick={() => onClose()}/>
          </div>
          <div className="modal-window-content small">
            <Field
              className={"create-page-block-element"}
              id="selliingAmount"
              label="Amount"
              type="input"
              error={errors.amount}
              value={values.amount}
              onChange={(value) => changeField("amount", value)}
            />
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Save'}
                loading={submitLoading}
                onClick={submit}
                type={'button'}
              />
            </div>
          </div>
        </div>
      )}
    />
  )

}

export default Sellings
