import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {useOnClickOutside} from "usehooks-ts";

function SingleSelect({onChange, elements: initialElements, value, placeholder, onLoadElements, secured}: any) {

  const token = useSelector(state => state.user.token)
  const [open, setOpen] = useState<boolean>(false)
  const [elements, setElements] = useState<any>([])
  const [loading, setLoading] = useState(false);

  const ref = useRef(null)

  const handleClickOutside = () => {
    setOpen(false)
  }

  useOnClickOutside(ref, handleClickOutside)

  useEffect(() => {

    if (Array.isArray(initialElements)) {
      setElements(initialElements)
    }

    if (onLoadElements) {
      loadElements()
    }

  }, [])

  const loadElements = async () => {

    setLoading(true)

    const elements = secured === true ? await onLoadElements(token) : await onLoadElements()
    setElements(elements)
    setLoading(false)

  }

  const select = (element: any) => {

      setOpen(false)
      onChange(element)

  }

  return (
    <div className="custom-select-select" ref={ref}>
      <div className="custom-select-top" onClick={() => {
        setOpen(!open)
      }}>
        {loading && (
          <div className="custom-select-top-spinner" style={{right: 28}}>
            <div className="spinner"/>
          </div>
        )}
        <span className="custom-select-top-label">{value !== null ? value : placeholder}</span>
        <img src="/images/arrow.svg" className="custom-select-top-arrow" onClick={() => {
          setOpen(!open)
        }}/>
      </div>
      {
        elements.length > 0 && open && (
          <div className="custom-select-submenu">
            {
              elements.map(function (element: any) {
                return (
                  <span
                    key={element.place_id}
                    className="custom-select-submenu-element"
                    onClick={() => {
                      select(element);
                    }}
                  >
                    {element}
                  </span>
                )
              })
            }
          </div>
        )
      }
    </div>
  );
}

export default SingleSelect;
