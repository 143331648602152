import React, {useEffect, useState} from 'react';

export function TaskMembers({members}: {
  members: []
}) {

  return (
    <>
      <div className="task-page-members">
        {members.map((member: any) => {
          return (
            <div className="task-page-member">
              <div className="task-page-member-left">
                <img src="/images/avatar.svg" alt="" className="task-page-member-left-image"/>
              </div>
              <div className="task-page-member-right">
                <div className="task-page-member-top">
                  <span className="task-page-member-name">{member.user.first_name} {member.user.last_name}</span>
                </div>
                <span className="task-page-member-label">{member.position}</span>
                <div className="task-page-member-actions row">
                  <span className="task-page-member-action">Searching for a stringer</span>
                  <span className="task-page-member-action">Communication with stringer</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {1 !== 1 && (
        <div className="task-page-members-create">
          <img src="/images/circle-plus.svg" alt="" className="task-page-members-create-icon"/>
          <span className="task-page-members-create-label">Add member</span>
        </div>
      )}
    </>
  )

}
