import React, {useEffect, useState} from 'react';
import {apiEmployeesGet} from "../../../../../actions/employeeAction";
import {useSelector} from "react-redux";
import Field from "../../../../../components/ui/Field";

function ProducerSelect({selectedProducer, onChange}: any) {

  const token = useSelector(state => state.user.token)

  const [producers, setProducers] = useState<any>([])

  useEffect(() => {

    loadProducers()

  }, [])

  const loadProducers = async () => {

    const response : any = await apiEmployeesGet({
      token,
      cancelToken: null,
      search: '',
      filters: '',
      offset: 0,
      sortColumn: '',
      sortDir: ''
    })
    setProducers(response.employee)

  }

  const generateValue = (producer: any) => {
    if (producer.user !== null) {
      return `${producer.user.first_name} ${producer.user.last_name}`
    }
    return '';
  }

  if (producers.length > 0) {
    return (
      <div>
        <Field
          className={"create-page-block-element"}
          id="uploadCategories"
          label="Producer *"
          type="singleSelect"
          placeholder='Select producer'
          error={''}
          options={{
            elements: producers.map((producer: any) => generateValue(producer))
          }}
          value={selectedProducer !== null ? generateValue(selectedProducer) : null}
          onChange={(value) => {
            const obj = producers.find((producer: any) => value === generateValue(producer))
            if (obj !== undefined) {
              onChange(obj)
            }
          }}
        />
        {selectedProducer !== null && (
          <div className="user-inner">
            <img src="/images/avatar.svg" alt="" className="user-inner-image"/>
            <div className="user-inner-block-1">
              <span className="user-inner-username">{generateValue(selectedProducer)}</span>
              <span className="user-inner-position">{selectedProducer.position}</span>
            </div>
            <div className="user-inner-block-2">
              <span className="user-inner-element"><b>e-mail:</b> {selectedProducer.user.email}</span>
              <span className="user-inner-element"><b>phone:</b> {selectedProducer.phone}</span>
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;

}

export default ProducerSelect;
