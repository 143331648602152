import React, {useState} from "react";
import TablePage, {TableConfig} from "../../../components/table/TablePage";
import {apiEmployeesGet} from "../../../actions/employeeAction";
import EmployeeCreateModal from "./components/EmployeeCreateModal";

const configHeads = [
  {
    name: '',
    width: '20px',
    key: 'empty',
  },
  {
    name: 'Name',
    width: null,
    key: 'name',
    type: 'link',
    options: {
      href: '/panel/employee/',
    }
  },
  {
    name: 'Email',
    width: '170px',
    key: 'email',
    type: 'string',
  },
  {
    name: 'Position',
    width: '170px',
    key: 'position',
    type: 'string',
  },
  {
    name: 'Phone',
    width: '170px',
    key: 'phone',
    type: 'string',
  },
]

const config : TableConfig = {
  title: 'Employees',
  key: 'employee',
  functions: {
    loadElementsMethod: apiEmployeesGet,
    convertElementsMethod: (employees) => {
      return employees.map((employee) => {
        return {
          id: employee.id,
          name: [employee.user?.first_name, employee.user?.last_name].join(' '),
          email: employee.user.email,
          position: employee.position,
          phone: employee.phone,
        }
      })
    }
  },
  options: {
    hasSearch: false,
    hasSwitcher: false,
  },
  filters: [],
  heads: configHeads,
}

function PanelEmployees() {

  const [createModalOpen, setCreateModalOpen] = useState(false)

  return (
    <>
      <EmployeeCreateModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false)
        }}
        onUpdate={() => {
          window.location.reload()
        }}
      />
      <TablePage
        config={{
          ...config,
          options: {
            ...config.options,
            createButton: {
              label: 'Create employee',
              action: () => {
                setCreateModalOpen(true)
              }
            },
          }
        }}
      />
    </>
  );

}

export default PanelEmployees
