import React from 'react';

function UgcContent() {
  return (
    <>

    </>
  );
}

export default UgcContent
