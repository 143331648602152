import {API_URL} from "../core/common";
import axios from "axios";
import moment from "moment";

export const apiPortfolioCreate = (token: string, data: {
  title: string
  filmingDate: string
  client: string
  links: string[],
  description: string
}) => {

  return new Promise((resolve, reject) => {

    const {title, filmingDate, client, links, description} = data;

    axios.post(`${API_URL}/portfolio.create`,  {
      title,
      filming_date: moment(filmingDate).format("YYYY-MM-DD"),
      client,
      links: links.join(','),
      description,
    },{
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiPortfoliosGet = (token: string, stringer_id = '') => {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}/portfolios.get?stringer_id=${stringer_id}`,{
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data.portfolios)
    }).catch(function (error) {
      reject(error);
    })

  })

}
