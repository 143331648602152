import React, {useEffect, useState} from 'react';
import {apiBriefGetCountries} from "../../../actions/briefActions";

const showFilter = false;

function BriefsFilters({
  filters,
  onChange
} : any) {

  const [countries, setCoutries] = useState<any>([])

  useEffect(() => {
    loadCountries()
  }, [])

  const loadCountries = async () => {

    const response = await apiBriefGetCountries()
    setCoutries(response)

  }

  const checkFilter = (id: string, category: any) => {
    return filters.find((filter: any) => filter.id === id && filter.value === category) !== undefined
  }

  const selectCountry = (country: any) => {

    if (checkFilter('Country', country)) {
      deleteFilter('Country', country);
    } else {
      onChange([
        ...filters,
        {
          id: 'Country',
          label: 'Country',
          value: country
        }
      ])
    }

  }

  const deleteFilter = (id: string, value: string) => {
    onChange(filters.filter((filter: any) => (filter.value !== value && filter.id === id) || filter.id !== id))
  }

  return (
    <div className="gallery-videos-left-inner">
      {countries.length > 0 && (
        <div className="gallery-filter-area">
          <span className="gallery-filter-area-title">Countries</span>
          <div className="gallery-filter-tips">
            {countries.map((country: any) => {
              return (
                <span onClick={() => {
                  selectCountry(country)
                }} className={`gallery-filter-tip ${checkFilter('Country', country) && 'active'}`}>{country}</span>
              )
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default BriefsFilters;
