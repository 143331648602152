import {createEmptyFieldText, createLocationSelectText, createPasswordMismatchText} from "./text";

export const formGenerateErrorsObject = (errorsList: object) : object => {

  let result : any = {};

  for (const [key] of Object.entries(errorsList)) {
    result[key] = "";
  }

  return result;

}

export const formCheckErrors = (errorsList: object, errors: any, fields: any) : {
  hasError: boolean,
  errors: object
} => {

  let hasError = false;

  for (const [key, value] of Object.entries(errorsList)) {

    const values = value.split('|');
    if (values.indexOf('email') !== -1) {
      if (!formValidateEmail(fields[key])) {
        errors[key] = createEmptyFieldText(key);
        hasError = true;
      }
    }
    if (values.indexOf('password') !== -1) {
      if (!formValidatePassword(fields[key])) {
        errors[key] = createEmptyFieldText(key);
        hasError = true;
      }
    }
    if (values.indexOf('repeat') !== -1) {
      if (fields['password'] !== "") {
        if (fields['password'] !== fields['repeatPassword']) {
          errors[key] = createPasswordMismatchText();
          hasError = true;
        }
      }
    }
    if (values.indexOf('required') !== -1) {
      if (fields[key].length === 0) {
        errors[key] = createEmptyFieldText(key);
        hasError = true;
      }
    }
    if (values.indexOf('location') !== -1) {
      if (fields[key] === null) {
        errors[key] = createLocationSelectText(key);
        hasError = true;
      }
    }
    if (values.indexOf('categories') !== -1) {
      if (fields[key] === null) {
        errors[key] = createLocationSelectText(key);
        hasError = true;
      }
    }
    if (values.indexOf('date') !== -1) {
      if (fields[key] === null) {
        errors[key] = createEmptyFieldText(key);
        hasError = true;
      }
    }

  }

  return {
    hasError,
    errors
  }

}

export const formValidatePassword = (password: any) => {
  return password.trim().length >= 6;
}

export const formValidateEmail = (email: any) => {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
}
