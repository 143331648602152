import React from 'react';
import TablePage, {TableConfig, TableFilter, TableHead} from "../../../components/table/TablePage";
import {TaskResponse} from "../../../types/responses";
import {apiClientsGet} from "../../../actions/clientActions";

const configHeads : TableHead[] = [
  {
    name: '',
    width: '20px',
    key: 'empty',
  },
  {
    name: 'Name',
    width: null,
    key: 'name',
    type: 'string',
  },
  {
    name: 'Organization',
    width: '200px',
    key: 'organization',
    type: 'string',
  },
  {
    name: 'Email',
    width: '170px',
    key: 'email',
    type: 'string',
  },
  {
    name: 'Phone',
    width: '175px',
    key: 'contact_phone',
    type: 'string',
  },
  {
    name: 'Country',
    width: '120px',
    key: 'country',
    type: 'string',
  },
  {
    name: 'Position',
    width: '120px',
    key: 'position',
    type: 'string',
  },
  {
    name: 'Created at',
    width: '90px',
    key: 'created_at',
    type: 'date',
    options: {
      format: 'DD/MM/YYYY HH:mm:ss'
    }
  },
]

const configFilters : TableFilter[] = []

const config : TableConfig = {
  title: 'Clients',
  key: 'clients',
  functions: {
    loadElementsMethod: apiClientsGet,
    convertElementsMethod: (elements: TaskResponse[]) => {
      return elements.map((element: any) => {
        return {
          ...element,
          name: element.user.first_name + ' ' + element.user.last_name,
          email: element.user.email
        }
      })
    }
  },
  options: {
    hasSearch: true,
    hasSwitcher: false,
  },
  heads: configHeads,
  filters: configFilters
}

function PanelClients() {

  return (
    <TablePage
      config={config}
    />
  );

}

export default PanelClients;
