import React, {useEffect, useState} from 'react';
import MessengerSelect from "./components/MessengerSelect";
import {useSelector} from "react-redux";
import {apiUserGetContacts} from "../../actions/userActions";

function Messengers({values, onChange} : any) {

  const token = useSelector(state => state.user.token)
  const [contacts, setContacts] = useState<any>([]);

  useEffect(() => {

    loadContacts()

  }, [])

  const loadContacts = async () => {

    const contacts = await apiUserGetContacts(token)
    setContacts(contacts)
    console.log(contacts)

  }

  const addContact = () => {

    if (contacts.length > 0) {

      onChange([...values, {
        contact: contacts[0],
        value: '',
        icon: contacts[0].icon
      }])

    }

  }

  const changeContact = (element: any, index: number) => {
    values[index].contact = element
    onChange(values)
  }

  const changeValue = (value: string, index: number) => {
    values[index].value = value
    onChange(values)
  }

  const deleteElement = (index: number) => {
    values.splice(index, 1)
    onChange(values)
  }

  return (
    <div className="custom-text-field">
      <label className="custom-text-field-label">Messengers</label>
      {values.length > 0 &&
        values.map((contact: any, index: number) => {
          return (
            <div className="messengers-row row">
              <div className="messengers-select">
                <MessengerSelect
                  contacts={contacts}
                  contact={contact.contact}
                  onChange={(element: any) => {
                    changeContact(element, index)
                  }}
                />
              </div>
              <div className="messengers-field">
                <input
                  className="custom-text-field-input"
                  type="text"
                  value={contact.value}
                  onChange={(event: any) => {
                    changeValue(event.target.value, index)
                  }}
                />
              </div>
              <button className="messengers-delete-icon" onClick={() => {
                deleteElement(index)
              }}>
                <img src="/images/trash.svg" alt="" className="messengers-delete-icon-image"/>
              </button>
            </div>
          )
        })
      }
      <button className="messengers-create-button" onClick={addContact}>
        <img className="messengers-create-button-icon" src="/images/circle-plus.svg" alt=""/>
        <span className="messengers-create-button-label">Add messenger</span>
      </button>
    </div>
  );
}

export default Messengers;
