import React, {useEffect, useState} from 'react';
import Loading from "../../components/ui/Loading";
import Button from "../../components/ui/Button";
import queryString from "query-string";
import {apiUserActivate, apiUserChangePassword, apiUserRegister, apiUserResetPassword} from "../../actions/userActions";
import {userSignInAction} from "../../reducers/user";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {formCheckErrors, formGenerateErrorsObject} from "../../core/form";
import Field from "../../components/ui/Field";
import ConfirmModal from "../../components/modals/ConfirmModal";
import useModal from "../../hooks/useModal";

const errorsList = {
  password: 'password',
  repeatPassword: 'repeat'
}

const initialValues = {
  password: '',
  repeatPassword: '',
}

function Restore() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(false)

  const [submitLoading, setSubmitLoading] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [token, setToken] = useState<null | string>(null)

  const [successModalOpen, openSuccessModal, closeSuccessModal] = useModal();

  const [userToken, setUserToken] = useState<null | string>(null)
  const [user, setUser] = useState<any>(null)



  useEffect(() => {

    const query: any = queryString.parse(window.location.search);
    if (query['token'] === undefined) {
      setError(true)
    } else {
      setToken(query['token'])
    }

  }, [])

  const changeField = (field: string, value: any) => {
    setValues({...values, [field]: value})
    setErrors({...errors, [field]: ""})
  }

  const submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);
    console.log(errorResponse);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setSubmitLoading(true)

    try {

     if (token) {
       const apiResponse = await apiUserResetPassword(token, values.password);
       if (apiResponse.status) {
         setUserToken(apiResponse.token)
         setUser(apiResponse.user)
         openSuccessModal()
       } else {
         setErrors({...errors, password: apiResponse.error});
         setSubmitLoading(false);
       }
     }

    } catch (error) {
      console.log(error);
    }

  }

  return (
    <div className="error-page">
      <ConfirmModal
        open={successModalOpen}
        onClose={() => {
          closeSuccessModal()
          dispatch(userSignInAction({
            token: userToken,
            user: user
          }));
          navigate('/profile')
        }}
        title='Thank you!'
        message="Your password has been successfully changed."
        submitButtonText='OK'
      />
      <div className="error-page-container">
        <h1 className="error-page-title">Change Password</h1>
        <div className="error-page-text">Please enter a new password</div>
        <div className="error-page-fields">
          <Field
            className="auth-window-element"
            id="loginPassword"
            error={errors.password}
            label="Password *"
            type="input"
            options={{
              type: 'password'
            }}
            value={values.password}
            onChange={(value) => changeField('password', value)}
          />
          <Field
            className="auth-window-element"
            id="loginRepeatPassword"
            error={errors.repeatPassword}
            label="Repeat Password *"
            type="input"
            options={{
              type: 'password'
            }}
            value={values.repeatPassword}
            onChange={(value) => changeField('repeatPassword', value)}
          />
        </div>
        <Button
          className={'blue error-page-resend-button'}
          label={'Change password'}
          loading={submitLoading}
          onClick={() => {
            submit()
          }}
          type={'button'}
        />
      </div>
    </div>
  )

  if (error) {
    return (
      <div className="error-page">
        <div className="error-page-container">
          <h1 className="error-page-title">Oh, shoot</h1>
          <span className="error-page-sub-title">We couldn't restore your account</span>
        </div>
      </div>
    )
  }

  return (
    <div/>
  );
}

export default Restore;
