import React, {useState} from 'react';
import {useSelector} from "react-redux";
import TablePage, {TableAction, TableConfig, TableFilter, TableHead} from "../table/TablePage";
import {
  apiStringerCitiesSearch,
  apiStringerCountrySearch,
  apiStringersGet,
  apiStringerStateSearch
} from "../../actions/stringerActions";
import {Brief, Task} from "../../types/models";
import {StringerResponse} from "../../types/responses";
import {StringerTable} from "../../types/tables";
import {apiBriefInviteStringers} from "../../actions/briefActions";
import {apiTaskInviteStringers} from "../../actions/taskActions";
import useModal from "../../hooks/useModal";
import SelectExecutorModal from "../modals/SelectExecutorModal";

const configHeads : TableHead[] = [
  {
    key: 'id',
    type: 'checkbox',
  },
  {
    name: 'Name',
    width: null,
    key: 'name',
    type: 'link',
    options: {
      href: '/panel/stringers/',
    }
  },
  {
    name: 'Email',
    width: '170px',
    key: 'email',
    type: 'string',
  },
  {
    name: 'Phone',
    width: '175px',
    key: 'phone',
    type: 'string',
  },
  {
    name: 'Location',
    width: '230px',
    key: 'location_text',
    type: 'string',
  },
  {
    name: 'Status',
    width: '145px',
    key: 'status',
    type: 'status',
    options: {
      circle: {
        0: 'red',
        1: 'green'
      },
      label: {
        0: 'Not active',
        1: 'Activated',
      }
    }
  },
  {
    name: '',
    key: 'inviteButton',
    width: '120px',
    type: 'button',
    options: {
      label: 'Send',
      action: 'invite',
      complete: {
        text: 'Invited',
        flag: 'hasInvite'
      }
    }
  },
]

const configFilters : TableFilter[] = [
  {
    id: 'Status',
    type: 'select',
    label: 'Status',
    options: {
      label: 'Please select status',
      elements: ['Active', 'Not activated']
    }
  },
  {
    id: 'Country',
    type: 'search',
    label: 'Country',
    options: {
      label: 'Please search country',
      onSearch: apiStringerCountrySearch
    }
  },
  {
    id: 'State',
    type: 'search',
    label: 'State/Region',
    options: {
      label: 'Please search state or region',
      onSearch: apiStringerStateSearch
    }
  },
  {
    id: 'City',
    type: 'search',
    label: 'City',
    options: {
      label: 'Please search city',
      onSearch: apiStringerCitiesSearch
    }
  },
  {
    id: 'Other',
    type: 'select',
    label: 'Other',
    options: {
      label: 'Please select filter',
      elements: ['Uploaded videos', 'Completed tasks']
    }
  },
]

const config : TableConfig = {
  title: 'Stringers',
  key: 'stringers',
  functions: {
    loadElementsMethod: apiStringersGet,
    convertElementsMethod: null,
  },
  options: {
    hasSearch: true,
    hasSwitcher: false,
  },
  heads: configHeads,
  filters: configFilters,
}

function StringersWithSend({type, obj, initialLocation, proposes, onChangeProposes} : {
  obj: Brief | Task,
  type: 'brief' | 'task',
  proposes: number[],
  onChangeProposes: (numbers: number[]) => void
  initialLocation: {
    country: string
    state: string
    city: string
  } | null}
) {

  const token = useSelector(state => state.user.token)

  const [selectExecutorModalOpen, openSelectExecutorModal, closeSelectExecutorModal] = useModal()
  const [selectedExecutorId, setSelectedExecutorId] = useState<null | number>(null)

  const getInitialFilters = () => {

    let filters: any = [];

    if (initialLocation) {

      if (initialLocation.city !== null) {
        filters.push({
          id: 'City',
          label: 'City',
          value: initialLocation.city
        })
      }

      if (initialLocation.state !== null) {
        filters.push({
          id: 'State',
          label: 'State',
          value: initialLocation.state
        })
      }

      if (initialLocation.country !== null) {
        filters.push({
          id: 'Country',
          label: 'Country',
          value: initialLocation.country
        })
      }

    }

    return filters;

  }

  const executorAction: TableAction = {
    label: 'Select executor',
    name: 'executor',
    icon: 'user',
    single: true,
    function: async (ids: number[]) => {
      setSelectedExecutorId(ids[0])
      openSelectExecutorModal()
    },
  }

  const actions: TableAction[] = [
    {
      label: 'Send',
      name: 'invite',
      icon: 'send',
      function: async (ids: number[]) => {
        if (type === 'brief') {
          await apiBriefInviteStringers(token, obj.id, ids)
        }
        if (type === 'task') {
          await apiTaskInviteStringers(token, obj.id, ids)
        }
      },
      successCallback: (ids: number[], elements: StringerTable[]): StringerTable[] => {
        onChangeProposes([
          ...proposes,
          ...ids
        ])
        return elements.map(element => {
          if (ids.indexOf(element.id) !== -1) {
            return {
              ...element,
              hasInvite: true
            }
          }
          return element
        })
      }
    },
  ]

  if (type === 'task') {
    actions.push(executorAction)
  }

  return (
    <div className="task-page-content">
      <SelectExecutorModal
        open={selectExecutorModalOpen}
        onClose={closeSelectExecutorModal}
        stringerId={selectedExecutorId}
        taskId={obj.id}
      />
      <TablePage
        initialFilters={getInitialFilters()}
        config={{
          ...config,
          functions: {
            ...config.functions,
            convertElementsMethod: (elements: StringerResponse[]) : StringerTable[] => [...elements.map(stringer => {
              return {
                ...stringer,
                name: [stringer.user.first_name, stringer.user.last_name].join(' '),
                email: stringer.user.email,
                status: stringer.user.status,
                hasInvite: proposes.indexOf(stringer.id) !== -1
              }
            })],
          },
          actions: actions
        }}
      />
    </div>
  );
}

export default StringersWithSend;
